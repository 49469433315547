import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Select from "react-select";
import axios from "axios";
import { API_URL } from "../../const";
import Swal from "sweetalert2";
import Logo from "../../image/ca.png";

const Header = () => {
  const handleLogout = () => {
    sessionStorage.clear();
  };
  const [yearOptions, setYearOptions] = useState([]);
  const [fiscalYear, setFiscalYear] = useState(null);
  const getYear = () => {
    axios.post(API_URL.YEARS_LIST, {}).then((res) => {
      let ct = res.data.data.map((raw) => {
        return {
          label: raw,
          value: raw,
        };
      });
      axios.post(API_URL.MINE, {}).then((res) => {
        let sel = ct.find((d) => d.value === res.data.fiscal_year);
        setFiscalYear(sel);
        localStorage.setItem('CURRENT_YEAR',sel.value);
        sessionStorage.setItem("UserId",res.data.id);
      });
      setYearOptions(ct);
    });
  };
  useEffect(() => {
    getYear();
  }, []);

  const changeYear = (event) => {
    Swal.fire({
      title: "Are you sure?",
      text: "Want to change this financial year",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Confirm",
      denyButtonText: "no",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .post(API_URL.YEARS_CHANGE, { fiscal_year: event.value })
          .then((res) => {
            setFiscalYear(event);
            localStorage.setItem('CURRENT_YEAR',event.value);
            setTimeout(() => {
              window.location.reload();
            }, 100);
          });
      }
    });
  };

  const isCurrentYearSelected = ()=>{
    if(fiscalYear && fiscalYear.value === yearOptions[yearOptions.length - 1].value){
        return (<span>Current Year</span>);
    }
    return (<span> Previous Year</span>);
  };
  const isCurrentYear = ()=>{
    if(fiscalYear && fiscalYear.value === yearOptions[yearOptions.length - 1].value){
        return "#5ABE5A";
    }
    return "red";
  };

  return (
    <>
      <header className="header" style={{ backgroundColor: isCurrentYear() }}>
        <div className="top-header-section">
          <div className="container-fluid">
            <div
              className="row align-items-center"
              style={{ justifyContent: "space-between" }}
            >
              <div
                className="col-5"
                style={{
                  backgroundColor: "white",
                  padding: "13px",
                  maxWidth: "195px",
                  borderRadius: "15px",
                }}
              >
                <div className="logo my-3 my-sm-0">
                  <Link to="/dashboard">
                    <img
                      src={Logo}
                      alt="logo image"
                      className="img-fluid"
                      width="100"
                    />
                  </Link>
                </div>
              </div>
              <div className="col-2 year-text-blink text-center">
                {isCurrentYearSelected()}
              </div>
              <div className="col-3 text-right">
                <div className="user-block d-none d-lg-block">
                  <div className="row align-items-center">
                    <div className="col-lg-12 col-md-12 col-sm-12">
                      <div className="user-notification-block align-right d-inline-block">
                        <div style={{ width: "200px", textAlign: "left" }}>
                          <Select
                            options={yearOptions}
                            value={fiscalYear}
                            onChange={(event) => changeYear(event)}
                          />
                        </div>
                      </div>
                      <div className="user-info align-right dropdown d-inline-block header-dropdown">
                        <Link
                          data-toggle="dropdown"
                          className=" menu-style dropdown-toggle"
                        >
                          <div className="user-avatar d-inline-block">
                            <img
                              src="/assets/img/profiles/img-10.jpg"
                              alt="user avatar"
                              className="rounded-circle img-fluid"
                              width="55"
                            />
                          </div>
                        </Link>

                        <div className="dropdown-menu notification-dropdown-menu shadow-lg border-0 p-3 m-0 dropdown-menu-right">
                          <Link className="dropdown-item p-2" to="/employee">
                            <span className="media align-items-center">
                              <span className="lnr lnr-user mr-3"></span>
                              <span className="media-body text-truncate">
                                <span className="text-truncate">
                                  {sessionStorage.getItem("isUser")}
                                </span>
                              </span>
                            </span>
                          </Link>
                          <Link
                            className="dropdown-item p-2"
                            to="/"
                            onClick={handleLogout}
                          >
                            <span className="media align-items-center">
                              <span className="lnr lnr-power-switch mr-3"></span>
                              <span className="media-body text-truncate">
                                <span className="text-truncate">Logout</span>
                              </span>
                            </span>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;
