import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import PendingInvoice from '../pay/pending-invoice';
import Complete from '../pay/complate-invoice';
import Invoice from '../pay/invoice';
import Ledger from '../ledger/ledger';
import Receipt from '../pay/payment-receipt';

const Settings = () => {
  const [mode, setMode] = useState(1);
  const location = useLocation();
  
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const modeParam = queryParams.get("mode");
    if (modeParam) {
      setMode(parseInt(modeParam));
    }
  }, [location.search]);

  const handleModeChange = (newMode) => {
    setMode(newMode);
    const queryParams = new URLSearchParams(location.search);
    queryParams.set("mode", newMode);
    window.history.pushState({}, "", `${location.pathname}?${queryParams.toString()}`);
  };
  return (
    <>
      <div className="w-100">
        <div className="card shadow-sm ctm-border-radius">
          <div className="card-body">
            <div className="flex-row list-group list-group-horizontal-lg" style={{ display: 'flex', alignItems: 'center' }} id="v-pills-tab" role="tablist" aria-orientation="vertical">
              <a className= {mode === 1 ? "active list-group-item" : "list-group-item"} id="v-pills-home-tab" data-toggle="pill" href="reviews.html#v-pills-home" role="tab" aria-controls="v-pills-home" aria-selected={mode === 1} onClick={() => handleModeChange(1)}>Pending</a>
              <a className= {mode === 3 ? "active list-group-item" : "list-group-item"} id="v-pills-profile-tab" data-toggle="pill" href="reviews.html#v-pills-profile" role="tab" aria-controls="v-pills-profile" aria-selected={mode === 3} onClick={() => handleModeChange(3)}>Invoice</a>
              <a className={mode === 4 ? "active list-group-item" : "list-group-item"} id="v-pills-profile-tab" data-toggle="pill" href="reviews.html#v-pills-profile" role="tab" aria-controls="v-pills-profile" aria-selected={mode === 4} onClick={() => handleModeChange(4)}>Ledger</a>
              <a className={mode === 5 ? "active list-group-item" : "list-group-item"} id="v-pills-profile-tab" data-toggle="pill" href="reviews.html#v-pills-profile" role="tab" aria-controls="v-pills-profile" aria-selected={mode === 5} onClick={() => handleModeChange(5)}>Payment Receipt/Cash Bank</a>
            </div>
          </div>
        </div>
        {
          mode === 1 && <PendingInvoice />
        }
        {
          mode === 3 && <Invoice />
        }
        {
          mode === 4 && <Ledger />
        }
        {
          mode === 5 && <Receipt />
        }
      </div>
    </>
  )
}

export default Settings;
