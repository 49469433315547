import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { IoVideocamOutline, IoDocumentsOutline } from "react-icons/io5";
import { API_URL } from "../../const";
import { Dropdown } from "primereact/dropdown";
import { RxCross2 } from "react-icons/rx";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import axios from "axios";

const TaskModal = ({ open, onCloseModal, row }) => {
  const [showModal, setShowModal] = useState(false);
  const [defaultActiveKey, setDefaultActiveKey] = useState("");
  const [employeeList, setEmployeeList] = useState([]);
  const [stepData, setStepData] = useState([]);
  const currentUserId = sessionStorage.getItem("UserId");
  const statusOption = [
    { name: "Pending", code: "Pending" },
    { name: "Completed", code: "Completed" },
  ];

  const getEmployee = () => {
    axios.post(API_URL.EMP_LIST, {}).then((res) => {
      let employess = [];
      res.data.data.map((element) => {
        employess.push({ value: element.id, label: element.name });
      });
      setEmployeeList(employess);
    });
  };

  const getEmployeeName = (employee_id) => {
    let sel = employeeList.find((d) => d.value === employee_id);
    if (sel) {
      return sel.label;
    }
    return "N/A";
  };

  const getSteps = (element) => {
    axios
      .post(API_URL.TASK_CLIENT, {
        client_id: element.client_id,
        lookup_id: element.lookup_id,
      })
      .then((res) => {
        if (res.data.status === 1 && res.data.data.length > 0) {
          const groupedItems = res.data.data.reduce(
            (ob, item) => ({
              ...ob,
              [item.task_view]: [...(ob[item.task_view] ?? []), item],
            }),
            {}
          );
          setStepData(groupedItems);
          Object.keys(groupedItems).map((keyName, i) => {
            if (i === 0 && !defaultActiveKey) {
              setDefaultActiveKey(keyName);
            }
          });
          setShowModal(true);
        } else {
          setShowModal(false);
          onCloseModal();
          toast.info("Task not available...");
        }
      });
  };

  const updateStepTaskStatus = (task, status, element, keyName, k) => {
    axios
      .post(API_URL.TASK_UPDATE_STATUS, {
        client_id: task.client_id,
        lookup_id: task.lookup_id,
        id: task.id,
        status: status,
      })
      .then((res) => {
        if (res.data.status === 1) {
          let cpStepData = {...stepData};
          cpStepData[keyName][k].status = status;
          setStepData(cpStepData);
          // getSteps(element);
          toast.success("Status update successfully");
        } else {
          toast.error("Somthing went wrong");
          onCloseModal();
        }
      });
  };

  const finishTask = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to finish this task",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085D6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, finish it!",
    }).then((result) => {
      if (result.isConfirmed) {
        let payload = {
          id: row.id,
          client_id: row.client_id,
          lookup_id: row.lookup_id,
          accounting_status: [{ value: "March" }],
          status: 1,
          task_view: defaultActiveKey,
        };

        let ApiUrl = API_URL.NOT_ROUTING_UPDATE;
        if (row.lookup_id == 49) {
          ApiUrl = API_URL.CLIENT_GSTR1_STATUS_UPDATE;
        }
        if (row.lookup_id == 50) {
          ApiUrl = API_URL.CLIENT_GSTR3B_STATUS_UPDATE;
        }
        if (row.lookup_id == 1) {
          ApiUrl = API_URL.CLIENT_ITRETURN_STATUS_UPDATE;
          payload.status = "eVerified";
        }
        if (row.lookup_id == 2) {
          ApiUrl = API_URL.CLIENT_ACCOUNTING_STATUS_UPDATE;
        }
        if (row.lookup_id == 8) {
          ApiUrl = API_URL.CLIENT_TRUST_AUDIT_STATUS_UPDATE;
          payload.status = [{ value: "Filled" }];
        }
        if (row.lookup_id == 3) {
          ApiUrl = API_URL.CLIENT_ITAUDIT_STATUS_UPDATE;
          payload.status = [{ value: "eVerified" }];
        }
        if (row.lookup_id == 6) {
          ApiUrl = API_URL.CLIENT_GSTR9_STATUS_UPDATE;
          payload.is_complete = 1;
        }
        if (row.lookup_id == 7) {
          ApiUrl = API_URL.CLIENT_GSTR9C_STATUS_UPDATE;
          payload.is_complete = 1;
        }
        if (row.lookup_id == 9) {
          ApiUrl = API_URL.CLIENT_TDS_COMPLETE;
        }
        if (row.lookup_id == 10) {
          ApiUrl = API_URL.CLIENT_CC_RENEWALS_STATUS_UPDATE;
          payload.is_complete = 1;
        }
        if (row.lookup_id == 4) {
          ApiUrl = API_URL.CLIENT_LLP_AUDIT_STATUS_UPDATE;
          payload.status = [{ value: "Filled" }];
        }
        if (row.lookup_id == 11) {
          ApiUrl = API_URL.CLIENT_IEC_RENEWALS_STATUS_UPDATE;
          payload.is_complete = 1;
        }
        axios.post(ApiUrl, payload).then((res) => {
          if (res.data.status !== 1) {
            toast.error(res.data.data);
          } else {
            toast.success("Task finished successfully");
            setShowModal(false);
            onCloseModal(true);
          }
        });
      }
    });
  };

  const checkPreviousStatus = (task, key) => {
    if (key > 0) {
      return task[key - 1].status === "Pending";
    }
    return false;
  };

  const checkNextStatus = (task, key) => {
    if (task.length - 1 != key) {
      return task[key + 1].status === "Completed";
    }
    return false;
  };

  const isLastStepCompleted = (task) => {
    return task[task.length - 1].status === "Pending";
  };

  useEffect(() => {
    if (open) {
      getSteps(row);
      getEmployee();
    } else {
      setShowModal(false);
    }
  }, [open]);

  return (
    <Modal
      className="step-modal"
      scrollable={true}
      show={showModal}
      size="xl"
      onHide={() => {
        onCloseModal();
        setShowModal(false);
      }}
      animation={true}
      centered
    >
      <Modal.Header className="modal-header-badges">
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <span className="badge badge-secondary">{row?.lookup?.key_name}</span>
          <div
            className="title ms-2"
            style={{ fontStyle: "italic", letterSpacing: "1px" }}
          >
            {row?.client?.firm_name}
          </div>
          <div
            className="title"
            style={{ letterSpacing: "1px", fontSize: "14px" }}
          >
            &nbsp;-&nbsp;+91 {row?.client?.person_one_no}
          </div>
        </div>
        <RxCross2
          size={25}
          onClick={() => {
            onCloseModal();
            setShowModal(false);
          }}
          style={{ cursor: "pointer" }}
        />
      </Modal.Header>
      <Modal.Body>
        <div className="note-modal">
          <Tabs
            defaultActiveKey={defaultActiveKey}
            onSelect={(k) => setDefaultActiveKey(k)}
            id="uncontrolled-tab-example"
            className="mb-3"
          >
            {Object.keys(stepData).map((keyName, i) => (
              <Tab eventKey={keyName} title={keyName} key={i}>
                <table className="table custom-table table-bordered">
                  <thead>
                    <tr>
                      <th className="text-center">No.</th>
                      <th>What ?</th>
                      <th>How ?</th>
                      <th>Who ?</th>
                      <th>Status</th>
                      <th>Time</th>
                      <th className="text-center">Help</th>
                    </tr>
                  </thead>
                  <tbody>
                    {stepData[keyName].map((element, key) => {
                      return (
                        <tr key={key}>
                          <td style={{ width: "40px" }} className="text-center">
                            {key + 1}
                          </td>
                          <td>{element.steps.for_what}</td>
                          <td>{element.steps.for_how}</td>
                          <td
                            style={{
                              width: "180px",
                              fontWeight: "800",
                              fontStyle: "italic",
                            }}
                          >
                            {getEmployeeName(element.steps.employee_id)}
                          </td>
                          <td style={{ width: "120px" }}>
                            <Dropdown
                              value={statusOption.find(
                                (d) => d.code === element.status
                              )}
                              onChange={(e) => {
                                updateStepTaskStatus(
                                  element,
                                  e.value.code,
                                  row,
                                  keyName,
                                  key
                                );
                              }}
                              options={statusOption}
                              disabled={
                                element.steps.employee_id != currentUserId ||
                                checkPreviousStatus(stepData[keyName], key) ||
                                checkNextStatus(stepData[keyName], key) ||
                                row.status == 1
                              }
                              optionLabel="name"
                              placeholder="Select a Status"
                              className=""
                            />
                          </td>
                          <td style={{ width: "80px" }}>
                            <span className="badge badge-info">
                              {element.steps.time_duration} Hours
                            </span>
                          </td>
                          <td style={{ width: "80px" }} className="text-center">
                            {element.steps.document_link && (
                              <a
                                href={element.steps.document_link}
                                target="_blank"
                                className="mr-2"
                              >
                                <IoDocumentsOutline color="#007bff" size={22} />
                              </a>
                            )}
                            {element.steps.video_link && (
                              <a
                                href={element.steps.video_link}
                                target="_blank"
                              >
                                <IoVideocamOutline size={22} />
                              </a>
                            )}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
                {row.status === 0 && (
                  <button
                    type="button"
                    disabled={isLastStepCompleted(stepData[keyName])}
                    onClick={finishTask}
                    className="btn btn-theme ctm-border-radius text-white float-right mt-3"
                  >
                    Finish Task
                  </button>
                )}
              </Tab>
            ))}
          </Tabs>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default TaskModal;
