import React, { useEffect, useState } from "react";
import axios from "axios";
import Select from "react-select";
import Modal from "react-bootstrap/Modal";
import NoteModal from "../../component/modal/note-modal";
import TagFilter from "../../component/filter/filterByTag";
import TaskModal from "../../component/modal/task-modal";
import { AiOutlineTag } from "react-icons/ai";
import { API_URL } from "../../const";
import { Oval } from "react-loader-spinner";
import { toast } from "react-toastify";
import { GrNotes } from "react-icons/gr";
import { CiViewColumn } from "react-icons/ci";
import { Form } from "react-bootstrap";
import { FiFilter } from "react-icons/fi";
import { RxCross2 } from "react-icons/rx";
import { FaTasks } from "react-icons/fa";
import { ThemeConsumer } from "react-bootstrap/esm/ThemeProvider";
const TDS = () => {
  const [isShow, setIsShow] = useState(false);
  const [loading, setLoading] = useState(true);
  const [itClient, setItClient] = useState([]);
  const [fiYear, setFiYear] = useState(null);
  const [shownotes, setShowNotes] = useState(false);
  const [showColum, setShowColum] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [isFilter, setFilter] = useState(false);
  const [allClientList, setAllClientList] = useState([]);
  const [drpClientList, setDrpClientList] = useState([]);
  const [currentRow, setCurrentRow] = useState({});
  const [showTaskModal, setShowTaskModal] = useState(false);
  const [selectedTags, setSelectedTags] = useState([]);
  const [formData, setFormData] = useState({
    client_id: "",
    month: "",
    month_view: "",
  });

  const [selectedColumns, setSelectedColumns] = useState([
    { label: "File No", value: "file_no", search: "" },
    { label: "Trade Name", value: "firm_name", search: "" },
    { label: "Mobile No", value: "person_one_no", search: "" },
  ]);

  const [columns, setColumns] = useState([
    { label: "Client Status", value: "is_active", search: "" },
    { label: "File No", value: "file_no", search: "" },
    { label: "Client Name", value: "name", search: "" },
    { label: "Firm Type", value: "firm_type", search: "" },
    { label: "Firm Name", value: "firm_name", search: "" },
    { label: "Address Line 1", value: "address_line_one", search: "" },
    { label: "Address Line 2", value: "address_line_two", search: "" },
    { label: "City", value: "city", search: "" },
    { label: "State", value: "state", search: "" },
    { label: "Pincode", value: "pincode", search: "" },
    { label: "Group Name", value: "group_name", search: "" },
    { label: "Sub Group Name", value: "sub_group_name", search: "" },
    { label: "Joining Date", value: "joining_date", search: "" },
    { label: "Contact Person-1 Name", value: "person_one_name", search: "" },
    { label: "Contact Person-1 Mobile No", value: "person_one_no", search: "" },
    { label: "Contact Person-1 Email", value: "email_one", search: "" },
    { label: "Contact Person-2 Name", value: "person_two_name", search: "" },
    { label: "Contact Person-2 Mobile No", value: "person_two_no", search: "" },
    { label: "Contact Person-2 Email", value: "email_two", search: "" },
    { label: "Mehtaji Name", value: "mehtaji_name", search: "" },
    { label: "Mehtaji No", value: "mehtaji_no", search: "" },
    { label: "PAN No", value: "pan_no", search: "" },
    { label: "PAN Password", value: "pan_password", search: "" },
    { label: "TAN No", value: "tan_no", search: "" },
    { label: "Trace Username", value: "trace_username", search: "" },
    { label: "Trace Password", value: "trace_password", search: "" },
    { label: "Income Tax Password", value: "income_tax_password", search: "" },
    { label: "GSTIN No", value: "gstin_no", search: "" },
    { label: "GSTIN Username", value: "gstin_username", search: "" },
    { label: "GSTIN Password", value: "gstin_password", search: "" },
    { label: "E-waybill Username", value: "ewaybill_username", search: "" },
    { label: "E-waybill Password", value: "ewaybill_password", search: "" },
    { label: "IEC No", value: "iec_no", search: "" },
    { label: "IEC Username", value: "iec_username", search: "" },
    { label: "IEC Password", value: "iec_password", search: "" },
    { label: "MSME No", value: "msme_no", search: "" },
  ]);

  const [isStatus, setIsStatus] = useState([
    { id: 1, name: "Q1-24Q", checked: false },
    { id: 2, name: "Q1-26Q", checked: false },
    { id: 3, name: "Q1-27EQ", checked: false },

    { id: 4, name: "Q2-24Q", checked: false },
    { id: 5, name: "Q2-26Q", checked: false },
    { id: 6, name: "Q2-27EQ", checked: false },

    { id: 7, name: "Q3-24Q", checked: false },
    { id: 8, name: "Q3-26Q", checked: false },
    { id: 9, name: "Q3-27EQ", checked: false },

    { id: 10, name: "Q4-24Q", checked: false },
    { id: 11, name: "Q4-26Q", checked: false },
    { id: 12, name: "Q4-27EQ", checked: false },
  ]);
  const [statusOption, setStatusOption] = useState([
    { label: "24Q", value: "24Q", id: 1 },
    { label: "26Q", value: "26Q", id: 2 },
    { label: "27EQ", value: "27EQ", id: 3 },
  ]);
  const [monthOption, setMomthOption] = useState([
    { label: "January", value: "Jan" },
    { label: "February", value: "Feb" },
    { label: "March", value: "Mar" },
    { label: "April", value: "Apr" },
    { label: "May", value: "May" },
    { label: "June", value: "Jun" },
    { label: "July", value: "Jul" },
    { label: "August", value: "Aug" },
    { label: "September", value: "Sep" },
    { label: "Octomber", value: "Oct" },
    { label: "November", value: "Nov" },
    { label: "December", value: "Dec" },
  ]);
 
  const renderCheckBox = (row, key, filtertype) => {
    return (
      <Form key={filtertype + "-" + key}>
        {["checkbox"].map((type) => (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              marginRight: "15px",
            }}
            key={`${filtertype}-${row.id}`}
          >
            <Form.Check
              style={{ display: "flex", alignItems: "center" }}
              type={type}
              checked={row.checked}
              id={`${filtertype}-${row.id}`}
              label={row.name}
              onChange={(e) => {
                let items = [];
                if (filtertype === "Status") {
                  items = [...isStatus];
                  items[key].checked = e.target.checked;
                  setIsStatus(items);
                }
              }}
            />
          </div>
        ))}
      </Form>
    );
  };

  const getClient = () => {
    axios.post(API_URL.CLIENT_TDS, {}).then((res) => {
      if (res.data.status === 1) {
        res.data.data.map((item) => {
          item.label = item.firm_name;
          item.value = item.id;
        });
        setDrpClientList(res.data.data);
      }
    });
  };

  const isStatusFilter = (obj) => {
    let selChecked = isStatus.filter((d) => d.checked);
    let status = selChecked.map((res) => {
      return res.id;
    });
    if (obj.tds_status) {
      for (let i = 0; i < obj.tds_status.length; i++) {
        if (status.includes(obj.tds_status[i])) {
          return true;
        }
      }
    }
    return false;
  };

  const tagFilter = (obj) => {
    let flag = false;
    selectedTags.map((res) => {
      let sel = obj.client_tags.find((d) => d.id === res.value);
      if (sel) {
        flag = true;
      }
    });
    return flag;
  };

  useEffect(() => {
    let copyData = [...allClientList];
    let selSearch = selectedColumns.filter((d) => d.search);
    let selChecked = isStatus.filter((d) => d.checked);
    let status = selChecked.map((res) => {
      return res.id;
    });
    if (selSearch.length > 0) {
      let filterArray = [];
      copyData.map((res) => {
        let flagArray = [];
        selSearch.map((filter) => {
          if (res[filter.value]) {
            let flag =
              res[filter.value]
                .toLowerCase()
                .indexOf(filter.search.toLowerCase()) > -1
                ? true
                : false;
            if (flag) {
              if (status.length > 0) {
                if (isStatusFilter(res)) {
                  flagArray.push(flag);
                }
              } else {
                flagArray.push(flag);
              }
            }
          }
        });
        if (flagArray.length === selSearch.length) {
          filterArray.push(res);
        }
      });
      setItClient(filterArray);
    } else {
      let sel = [];
      copyData.map((res) => {
        if (isStatusFilter(res) || tagFilter(res)) {
          sel.push(res);
        }
      });
      if (sel.length === 0 && status.length === 0) {
        setItClient(allClientList);
      } else {
        setItClient(sel);
      }
    }
    setFilter(false);
  }, [selectedColumns, isFilter, selectedTags]);

  useEffect(() => {
    let today = new Date();
    let year;
    if (today.getMonth() < 3) {
      year = today.getFullYear() - 1 + "-" + today.getFullYear();
    } else {
      year = today.getFullYear() + "-" + (today.getFullYear() + 1);
    }
    setFiYear(year);
    getItClient(year);
  }, []);

  const hideShowColumns = (e) => {
    setSelectedColumns(e);
  };

  const getItClient = (year) => {
    axios.post(API_URL.CLIENT_TDS_LIST, { year: year }).then((res) => {
      if (res.data.status === 1) {
        res.data.data.map((row) => {
          row.tds_status =
            row.tds_status && row.tds_status.length
              ? row.tds_status.toString()
              : "";
        });
        setItClient(res.data.data); 
        setAllClientList(res.data.data);
      } else {
        toast.error(res.data.data, "", {
          autoClose: 3000,
          position: "top-center",
        });
      }
      setLoading(false);
    });
  };

  const handleTdsStatus = (e, res) => {
    let payload = {
      lookup_id: 9,
      client_id: res.id,
      year: fiYear,
      tds_status: e,
    };
    updateData(payload);
  };

  const updateData = (payload) => {
    axios.post(API_URL.CLIENT_TDS_STATUS_UPDATE, payload).then((res) => {
      if (res.data.status !== 1) {
        toast.error(res.data.data, "", {
          autoClose: 3000,
          position: "top-center",
        });
      }
    });
  };

const saveData = () => {
  let url = API_URL.CLIENT_TDS_SAVE;
  let body = { ...formData };

  axios.post(url, body).then((response) => {
    if (response.data.status === 1) {
      
      setFormData({
        client_id: "",
        month: "",
        month_view: "",
      });
      toast.success('Added', {
        autoClose: 3000,
        position: "top-center",
      });
      getItClient(fiYear); 
    } else {
      if (response.data && response.data.data) {
        toast.error(response.data.data, {
          autoClose: 3000,
          position: "top-center",
        });
      }
    }
  });
};

const deleteData = () => {
  let url = API_URL.CLIENT_TDS_DELETE;
  let body = { ...formData };

  axios.post(url, body).then((response) => {
    if (response.data.status === 1) {
      setFormData({
        client_id: "",
        month: "",
        month_view: "",
      });
      toast.success(response.data.data, {
        autoClose: 3000,
        position: "top-center",
      });
      getItClient(fiYear); 
    } else {
      if (response.data && response.data.data) {
        toast.error(response.data.data, {
          autoClose: 3000,
          position: "top-center",
        });
      }
    }
  });
};

  const isValidForm = () => {
    return formData.client_id && formData.month && formData.month_view;
  };

  const handleShowNotes = (note) => {
    setCurrentRow(note);
    setShowNotes(true);
  };

  useEffect(() => {
    getClient();
  }, []);

  return (
    <>
      <div className="w-100">
        <div className="ctm-border-radius shadow-sm card">
          <div className="card-body">
            <h4
              className="title card-title float-left"
              style={{ paddingLeft: "15px" }}
            >
              {itClient.length} Records
            </h4>
            <div className="d-flex align-items-baseline col-md-12 p-0 pb-2">
              <div className="col-md-3 pl-0">
                <Select
                  className="basic-single mr-2 w-100"
                  name="color"
                  options={drpClientList}
                  placeholder="Select Client.."
                  value={
                    formData.client_id
                      ? drpClientList.find((r) => r.value == formData.client_id)
                      : null
                  }
                  onChange={(e) =>
                    setFormData({ ...formData, client_id: e.value })
                  }
                />
              </div>
              <div className="col-md-3">
                <Select
                  className="basic-single w-100"
                  name="color"
                  options={statusOption}
                  placeholder="Select Return.."
                  value={
                    formData.month_view
                      ? statusOption.find((r) => r.value == formData.month_view)
                      : null
                  }
                  onChange={(e) =>
                    setFormData({ ...formData, month_view: e.value })
                  }
                />
              </div>
              <div className="col-md-3">
                <Select
                  className="basic-single w-100"
                  name="color"
                  options={monthOption}
                  placeholder="Select Month.."
                  value={
                    formData.month
                      ? monthOption.find((r) => r.value == formData.month)
                      : null
                  }
                  onChange={(e) => setFormData({ ...formData, month: e.value })}
                />
              </div>
              <div className="col-md-3">
                <button
                  className="btn btn-theme ctm-border-radius text-white"
                  onClick={saveData}
                  disabled={!isValidForm()}
                >
                  Generate
                </button>
                <button
                  className="btn btn-theme ctm-border-radius text-white ml-2"
                  onClick={deleteData}
                  disabled={!isValidForm()}
                >
                  Delete
                </button>
              </div>
            </div>
            <div
              className="tab-pane fade show active"
              id="pills-home"
              role="tabpanel"
              aria-labelledby="pills-home-tab"
            >
              {showColum && (
                <div className="row mb-3">
                  <div className="col">
                    <Select
                      isClearable={false}
                      className="basic-multi-select"
                      placeholder="Select columns which you want to display"
                      isMulti={true}
                      name="columns"
                      options={columns}
                      value={selectedColumns}
                      onChange={(e) => hideShowColumns(e)}
                    />
                  </div>
                </div>
              )}

              <Modal
                show={modalShow}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
              >
                <Modal.Header className="modal-header-badges">
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <div className="title">Filter By Status</div>
                  </div>
                  <RxCross2
                    size={25}
                    onClick={() => {
                      setModalShow(false);
                    }}
                    style={{ cursor: "pointer" }}
                  />
                </Modal.Header>
                <Modal.Body
                  style={{
                    display: "grid",
                    gridTemplateColumns: "repeat(3, 1fr)",
                  }}
                >
                  {isStatus.map((row, key) => {
                    return (
                      <div key={key}>{renderCheckBox(row, key, "Status")}</div>
                    );
                  })}
                </Modal.Body>
                <Modal.Footer>
                  <button
                    className="btn btn-danger text-white ctm-border-radius float-right mr-2"
                    onClick={() => {
                      let copyData = [...isStatus];
                      copyData.map((res) => {
                        res.checked = false;
                      });
                      setIsStatus(copyData);
                      setFilter(true);
                      setModalShow(false);
                    }}
                  >
                    Clear
                  </button>
                  <button
                    className="btn btn-theme button-1 text-white ctm-border-radius p-2 add-person ctm-btn-padding"
                    onClick={() => {
                      setFilter(true);
                      setModalShow(false);
                    }}
                  >
                    Apply
                  </button>
                </Modal.Footer>
              </Modal>

              <div className="employee-office-table">
                {loading ? (
                  <Oval
                    height={60}
                    width={60}
                    color="#7539ff"
                    wrapperStyle={{ justifyContent: "center" }}
                    wrapperClass=""
                    visible={true}
                    ariaLabel="oval-loading"
                    secondaryColor="#7539ff"
                    strokeWidth={2}
                    strokeWidthSecondary={2}
                  />
                ) : (
                  <div>
                    <table className="table custom-table table-bordered">
                      <thead>
                        <tr>
                          {selectedColumns.map((row, kd) => {
                            return (
                              <th
                                style={{
                                  maxWidth:
                                    kd > 2
                                      ? "5px"
                                      : kd == 1
                                      ? "250px"
                                      : "100px",
                                }}
                                key={kd}
                              >
                                {row.label}
                              </th>
                            );
                          })}
                          <th>Apr</th>
                          <th>May</th>
                          <th>Jun</th>
                          <th style={{ backgroundColor: "blue" }}>Return</th>
                          <th>Jul</th>
                          <th>Aug</th>
                          <th>Sep</th>
                          <th style={{ backgroundColor: "blue" }}>Return</th>
                          <th>Oct</th>
                          <th>Nov</th>
                          <th>Dec</th>
                          <th style={{ backgroundColor: "blue" }}>Return</th>
                          <th>Jan</th>
                          <th>Feb</th>
                          <th>Mar</th>
                          <th style={{ backgroundColor: "blue" }}>Return</th>
                          <th style={{ width: "50px" }}>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                              }}
                            >
                              <div>Action</div>
                              <div>
                                <CiViewColumn
                                  style={{ textAlign: "end" }}
                                  color="white"
                                  cursor={"pointer"}
                                  size={22}
                                  onClick={() => setShowColum(!showColum)}
                                />
                              </div>
                            </div>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          {selectedColumns.map((row, kd) => {
                            return (
                              <td
                                style={{
                                  width:
                                    kd > 2
                                      ? "42px"
                                      : kd == 0
                                      ? "40px"
                                      : kd == 1
                                      ? "250px"
                                      : "10%",
                                }}
                                key={kd}
                              >
                                {row.value === "file_no" ||
                                row.value === "firm_name" ||
                                row.value === "person_one_no" ||
                                row.value === "email_one" ? (
                                  <input
                                    placeholder="Search..."
                                    type="text"
                                    style={{
                                      width: "100%",
                                      minWidth: "50px",
                                      padding: "0px 8px",
                                    }}
                                    className="form-control form-control-sm"
                                    name="name"
                                    value={row.search}
                                    onChange={(e) => {
                                      let copyData = [...selectedColumns];
                                      copyData[kd].search = e.target.value;
                                      setSelectedColumns(copyData);
                                    }}
                                  />
                                ) : null}
                              </td>
                            );
                          })}
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td className="text-center">
                            <button
                              type="button"
                              className="btn btn-sm btn-red btn-action-table"
                              onClick={() => {
                                setIsShow(!isShow);
                              }}
                            >
                              <AiOutlineTag size={25} />
                            </button>
                          </td>
                        </tr>
                        {itClient.map((res, key) => {
                          return (
                            <tr key={key} className="tds-row">
                              {selectedColumns.map((row, lk) => {
                                return (
                                  <td key={key + "" + lk}>
                                    {row.value === "firm_name" ? (
                                      <>
                                        {res[row.value]}
                                        <div>
                                          {res.client_tags.map((el, tk) => {
                                            return (
                                              <span
                                                key={tk}
                                                style={{
                                                  backgroundColor: "#511989",
                                                }}
                                                className="badge badge-primary mr-1"
                                              >
                                                {el.name}
                                              </span>
                                            );
                                          })}
                                        </div>
                                      </>
                                    ) : (
                                      <>{res[row.value]} </>
                                    )}
                                  </td>
                                );
                              })}
                              <td style={{padding:"1px 2px" }}>
                                <div>
                                  {statusOption.map((element, key1) => {
                                    return (
                                      <div
                                        key={key1}
                                        className="mr-1"
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        <input
                                          className="mr-1"
                                          style={{ width: "10px !important" }}
                                          type="checkbox"
                                          readOnly
                                          checked={(res.tds_items.find((r) => (r.month_name == 'Apr' && r.quarter == element.value && r.is_complete == 1)))}
                                          disabled={!(res.tds_items.find((r) => (r.month_name == 'Apr' && r.quarter == element.value)))}
                                        />
                                        <label style={{ margin: "0px" }}>
                                          {element.label}
                                        </label>
                                      </div>
                                    );
                                  })}
                                </div>
                              </td>
                              <td style={{padding:"1px 2px" }}>
                                <div>
                                  {statusOption.map((element, key1) => {
                                    return (
                                      <div
                                        key={key1}
                                        className="mr-1"
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        <input
                                          className="mr-1"
                                          type="checkbox"
                                          readOnly
                                          checked={(res.tds_items.find((r) => (r.month_name == 'May' && r.quarter == element.value && r.is_complete == 1)))}
                                          disabled={!(res.tds_items.find((r) => (r.month_name == 'May' && r.quarter == element.value)))}
                                        />
                                        <label style={{ margin: "0px" }}>
                                          {element.label}
                                        </label>{" "}
                                      </div>
                                    );
                                  })}
                                </div>
                              </td>
                              <td style={{padding:"1px 2px" }}>
                                <div>
                                  {statusOption.map((element, key1) => {
                                    return (
                                      <div
                                        key={key1}
                                        className="mr-1"
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        <input
                                          className="mr-1"
                                          type="checkbox"
                                          readOnly
                                          checked={(res.tds_items.find((r) => (r.month_name == 'Jun' && r.quarter == element.value && r.is_complete == 1)))}
                                          disabled={!(res.tds_items.find((r) => (r.month_name == 'Jun' && r.quarter == element.value)))}
                                        />
                                        <label style={{ margin: "0px" }}>
                                          {element.label}
                                        </label>
                                      </div>
                                    );
                                  })}
                                </div>
                              </td>
                              <td style={{ backgroundColor: "burlywood",padding:"1px 2px" }}>
                                <div>
                                  {statusOption.map((element, key1) => {
                                    return (
                                      <div
                                        key={key1}
                                        className="mr-1"
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        <input
                                          className="mr-1"
                                          type="checkbox"
                                        />
                                        <label style={{ margin: "0px" }}>
                                          {element.label}
                                        </label>
                                      </div>
                                    );
                                  })}
                                </div>
                              </td>
                              <td style={{padding:"1px 2px" }}>
                                <div>
                                  {statusOption.map((element, key1) => {
                                    return (
                                      <div
                                        key={key1}
                                        className="mr-1"
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        <input
                                          className="mr-1"
                                          type="checkbox"
                                          name={"checkbox-" + key1}
                                          readOnly
                                          checked={(res.tds_items.find((r) => (r.month_name == 'Jul' && r.quarter == element.value && r.is_complete == 1)))}
                                          disabled={!(res.tds_items.find((r) => (r.month_name == 'Jul' && r.quarter == element.value)))}
                                        />
                                        <label style={{ margin: "0px" }}>
                                          {element.label}
                                        </label>{" "}
                                      </div>
                                    );
                                  })}
                                </div>
                              </td>
                              <td style={{padding:"1px 2px" }}>
                                <div>
                                  {statusOption.map((element, key1) => {
                                    return (
                                      <div
                                        key={key1}
                                        className="mr-1"
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        <input
                                          className="mr-1"
                                          type="checkbox"
                                          readOnly
                                          checked={(res.tds_items.find((r) => (r.month_name == 'Aug' && r.quarter == element.value && r.is_complete == 1)))}
                                          disabled={!(res.tds_items.find((r) => (r.month_name == 'Aug' && r.quarter == element.value)))}
                                        />
                                        <label style={{ margin: "0px" }}>
                                          {element.label}
                                        </label>{" "}
                                      </div>
                                    );
                                  })}
                                </div>
                              </td>
                              <td style={{padding:"1px 2px" }}>
                                <div>
                                  {statusOption.map((element, key1) => {
                                    return (
                                      <div
                                        key={key1}
                                        className="mr-1"
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        <input
                                          className="mr-1"
                                          type="checkbox"
                                          readOnly
                                          checked={(res.tds_items.find((r) => (r.month_name == 'Sep' && r.quarter == element.value && r.is_complete == 1)))}
                                          disabled={!(res.tds_items.find((r) => (r.month_name == 'Sep' && r.quarter == element.value)))}
                                        />
                                        <label style={{ margin: "0px" }}>
                                          {element.label}
                                        </label>{" "}
                                      </div>
                                    );
                                  })}
                                </div>
                              </td>
                              <td style={{ backgroundColor: "burlywood" ,padding:"1px 2px"}}>
                                <div>
                                  {statusOption.map((element, key1) => {
                                    return (
                                      <div
                                        key={key1}
                                        className="mr-1"
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        <input
                                          className="mr-1"
                                          type="checkbox"
                                          name={"checkbox-" + key1}
                                        />
                                        <label style={{ margin: "0px" }}>
                                          {element.label}
                                        </label>
                                      </div>
                                    );
                                  })}
                                </div>
                              </td>
                              <td style={{padding:"1px 2px" ,padding:"1px 2px"  }}>
                                <div>
                                  {statusOption.map((element, key1) => {
                                    return (
                                      <div
                                        key={key1}
                                        className="mr-1"
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        <input
                                          className="mr-1"
                                          type="checkbox"
                                          name={"checkbox-" + key1}
                                          readOnly
                                          checked={(res.tds_items.find((r) => (r.month_name == 'Oct' && r.quarter == element.value && r.is_complete == 1)))}
                                          disabled={!(res.tds_items.find((r) => (r.month_name == 'Oct' && r.quarter == element.value)))}
                                        />
                                        <label style={{ margin: "0px" }}>
                                          {element.label}
                                        </label>{" "}
                                      </div>
                                    );
                                  })}
                                </div>
                              </td>
                              <td style={{padding:"1px 2px" }}>
                                <div>
                                  {statusOption.map((element, key1) => {
                                    return (
                                      <div
                                        key={key1}
                                        className="mr-1"
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        <input
                                          className="mr-1"
                                          type="checkbox"
                                          name={"checkbox-" + key1}
                                          readOnly
                                          checked={(res.tds_items.find((r) => (r.month_name == 'Nov' && r.quarter == element.value && r.is_complete == 1)))}
                                          disabled={!(res.tds_items.find((r) => (r.month_name == 'Nov' && r.quarter == element.value)))}
                                        />
                                        <label style={{ margin: "0px" }}>
                                          {element.label}
                                        </label>{" "}
                                      </div>
                                    );
                                  })}
                                </div>
                              </td>
                              <td style={{padding:"1px 2px" }}>
                                <div>
                                  {statusOption.map((element, key1) => {
                                    return (
                                      <div
                                        key={key1}
                                        className="mr-1"
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        <input
                                          className="mr-1"
                                          type="checkbox"
                                          name={"checkbox-" + key1}
                                          readOnly
                                          checked={(res.tds_items.find((r) => (r.month_name == 'Dec' && r.quarter == element.value && r.is_complete == 1)))}
                                          disabled={!(res.tds_items.find((r) => (r.month_name == 'Dec' && r.quarter == element.value)))}
                                        />
                                        <label style={{ margin: "0px" }}>
                                          {element.label}
                                        </label>{" "}
                                      </div>
                                    );
                                  })}
                                </div>
                              </td>
                              <td style={{ backgroundColor: "burlywood", padding:"1px 2px" }}>
                                <div>
                                  {statusOption.map((element, key1) => {
                                    return (
                                      <div
                                        key={key1}
                                        className="mr-1"
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        <input
                                          className="mr-1"
                                          type="checkbox"
                                          name={"checkbox-" + key1}
                                        />
                                        <label style={{ margin: "0px" }}>
                                          {element.label}
                                        </label>
                                      </div>
                                    );
                                  })}
                                </div>
                              </td>
                              <td style={{padding:"1px 2px" }}>
                                <div>
                                  {statusOption.map((element, key1) => {
                                    return (
                                      <div
                                        key={key1}
                                        className="mr-1"
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        <input
                                          className="mr-1"
                                          type="checkbox"
                                          name={"checkbox-" + key1}
                                          readOnly
                                          checked={(res.tds_items.find((r) => (r.month_name == 'Jan' && r.quarter == element.value && r.is_complete == 1)))}
                                          disabled={!(res.tds_items.find((r) => (r.month_name == 'Jan' && r.quarter == element.value)))}
                                        />
                                        <label style={{ margin: "0px" }}>
                                          {element.label}
                                        </label>{" "}
                                      </div>
                                    );
                                  })}
                                </div>
                              </td>
                              <td style={{padding:"1px 2px" }}>
                                <div>
                                  {statusOption.map((element, key1) => {
                                    return (
                                      <div
                                        key={key1}
                                        className="mr-1"
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        <input
                                          className="mr-1"
                                          type="checkbox"
                                          name={"checkbox-" + key1}
                                          readOnly
                                          checked={(res.tds_items.find((r) => (r.month_name == 'Feb' && r.quarter == element.value && r.is_complete == 1)))}
                                          disabled={!(res.tds_items.find((r) => (r.month_name == 'Feb' && r.quarter == element.value)))}
                                        />
                                        <label style={{ margin: "0px" }}>
                                          {element.label}
                                        </label>{" "}
                                      </div>
                                    );
                                  })}
                                </div>
                              </td>
                              <td style={{padding:"1px 2px" }}>
                                <div>
                                  {statusOption.map((element, key1) => {
                                    return (
                                      <div
                                        key={key1}
                                        className="mr-1"
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        <input
                                          className="mr-1"
                                          type="checkbox"
                                          name={"checkbox-" + key1}
                                          readOnly
                                          checked={(res.tds_items.find((r) => (r.month_name == 'Mar' && r.quarter == element.value && r.is_complete == 1)))}
                                          disabled={!(res.tds_items.find((r) => (r.month_name == 'Mar' && r.quarter == element.value)))}
                                        />
                                        <label style={{ margin: "0px" }}>
                                          {element.label}
                                        </label>{" "}
                                      </div>
                                    );
                                  })}
                                </div>
                              </td>
                              <td style={{ backgroundColor: "burlywood",padding:"1px 2px" }}>
                                <div>
                                  {statusOption.map((element, key1) => {
                                    return (
                                      <div
                                        key={key1}
                                        className="mr-1"
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        <input
                                          style={{
                                            width: "10px",
                                            height: "10px",
                                          }}
                                          className="mr-1"
                                          type="checkbox"
                                          name={"checkbox-" + key1}
                                        />
                                        <label style={{ margin: "0px" }}>
                                          {element.label}
                                        </label>
                                      </div>
                                    );
                                  })}
                                </div>
                              </td>
                              {/* <td>
                                <div
                                  style={{
                                    display: "grid",
                                    gridTemplateColumns: "repeat(3, 1fr)",
                                  }}
                                >
                                  {statusOption.map((element, key1) => {
                                    return (
                                      <div
                                        key={key1}
                                        className="mr-3"
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        <input
                                          style={{
                                            width: "20px",
                                            height: "20px",
                                          }}
                                          className="mr-2"
                                          checked={
                                            res.tds_status &&
                                            res.tds_status.includes(element.id)
                                          }
                                          type="checkbox"
                                          name={"checkbox-" + key1}
                                          onChange={(event) => {
                                            let copyData = [...itClient];
                                            if (event.target.checked) {
                                              if (!copyData[key].tds_status) {
                                                copyData[key].tds_status = [];
                                              }
                                              copyData[key].tds_status.push(
                                                element.id
                                              );
                                            } else {
                                              let index = copyData[
                                                key
                                              ].tds_status.indexOf(element.id);
                                              copyData[key].tds_status.splice(
                                                index,
                                                1
                                              );
                                            }
                                            setItClient(copyData);
                                            handleTdsStatus(
                                              copyData[key].tds_status,
                                              res
                                            );
                                          }}
                                        />
                                        {element.label}
                                      </div>
                                    );
                                  })}
                                </div>
                              </td> */}
                              <td style={{width:"10px"}}>
                                <div className="action-section">
                                  <button
                                    type="button"
                                    className="btn btn-sm btn-red btn-action-table"
                                    onClick={() => {
                                      handleShowNotes(res);
                                    }}
                                  >
                                    <GrNotes size={22} />
                                  </button>
                                  <button
                                    type="button"
                                    className="btn btn-sm btn-red btn-action-table "
                                    onClick={() => {
                                      setCurrentRow(res);
                                      setShowTaskModal(true);
                                    }}
                                  >
                                    <FaTasks size={22} />
                                  </button>
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                )}
                <TagFilter
                  open={isShow}
                  onClose={(tags) => {
                    setIsShow(false);
                    if (tags) {
                      setSelectedTags(tags);
                    }
                  }}
                ></TagFilter>
                <NoteModal
                  firmType={"TDS"}
                  openNotes={shownotes}
                  note={currentRow}
                  onCloseNotes={() => setShowNotes(false)}
                ></NoteModal>
                <TaskModal
                  open={showTaskModal}
                  row={{
                    client_id: currentRow.id,
                    lookup_id: 9,
                    lookup: {
                      key_name: "TDS",
                    },
                    status: 0,
                    client: {
                      firm_name: currentRow.name,
                      person_one_no: currentRow.person_one_no,
                    },
                  }}
                  onCloseModal={() => setShowTaskModal(false)}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TDS;
