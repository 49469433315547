import React, { useEffect, useState } from "react";
import axios from "axios";
import Select from "react-select";
import Modal from "react-bootstrap/Modal";
import NoteModal from "../../component/modal/note-modal";
import TagFilter from "../../component/filter/filterByTag";
import TaskModal from "../../component/modal/task-modal";
import { AiOutlineTag } from "react-icons/ai";
import { API_URL } from "../../const";
import { Oval } from "react-loader-spinner";
import { toast } from "react-toastify";
import { GrNotes } from "react-icons/gr";
import { FiFilter } from "react-icons/fi";
import { RxCross2 } from "react-icons/rx";
import { Form } from "react-bootstrap";
import { CiViewColumn } from "react-icons/ci";
import { FaTasks } from "react-icons/fa";

const IEC = () => {
  const [isShow, setIsShow] = useState(false);
  const [loading, setLoading] = useState(true);
  const [itClient, setItClient] = useState([]);
  const [allClientList, setAllClientList] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const [isFilter, setFilter] = useState(false);
  const [fiYear, setFiYear] = useState(null);
  const [shownotes, setShowNotes] = useState(false);
  const [showColum, setShowColum] = useState(false);
  const [selectedTags, setSelectedTags] = useState([]);
  const [currentRow, setCurrentRow] = useState({});
  const [showTaskModal, setShowTaskModal] = useState(false);
  const [selectedColumns, setSelectedColumns] = useState([
    { label: "File No", value: "file_no" },
    { label: "Trade Name", value: "firm_name" },
    { label: "Mobile No", value: "person_one_no" },
    { label: "Email", value: "email_one" },
  ]);
  const [columns, setColumns] = useState([
    { label: "Client Status", value: "is_active" },
    { label: "File No", value: "file_no" },
    { label: "Client Name", value: "name" },
    { label: "Firm Type", value: "firm_type" },
    { label: "Firm Name", value: "firm_name" },
    { label: "Address Line 1", value: "address_line_one" },
    { label: "Address Line 2", value: "address_line_two" },
    { label: "City", value: "city" },
    { label: "State", value: "state" },
    { label: "Pincode", value: "pincode" },
    { label: "Group Name", value: "group_name" },
    { label: "Sub Group Name", value: "sub_group_name" },
    { label: "Joining Date", value: "joining_date" },
    { label: "Contact Person-1 Name", value: "person_one_name" },
    { label: "Contact Person-1 Mobile No", value: "person_one_no" },
    { label: "Contact Person-1 Email", value: "email_one" },
    { label: "Contact Person-2 Name", value: "person_two_name" },
    { label: "Contact Person-2 Mobile No", value: "person_two_no" },
    { label: "Contact Person-2 Email", value: "email_two" },
    { label: "Mehtaji Name", value: "mehtaji_name" },
    { label: "Mehtaji No", value: "mehtaji_no" },
    { label: "PAN No", value: "pan_no" },
    { label: "PAN Password", value: "pan_password" },
    { label: "TAN No", value: "tan_no" },
    { label: "Trace Username", value: "trace_username" },
    { label: "Trace Password", value: "trace_password" },
    { label: "Income Tax Password", value: "income_tax_password" },
    { label: "GSTIN No", value: "gstin_no" },
    { label: "GSTIN Username", value: "gstin_username" },
    { label: "GSTIN Password", value: "gstin_password" },
    { label: "E-waybill Username", value: "ewaybill_username" },
    { label: "E-waybill Password", value: "ewaybill_password" },
    { label: "IEC No", value: "iec_no" },
    { label: "IEC Username", value: "iec_username" },
    { label: "IEC Password", value: "iec_password" },
    { label: "MSME No", value: "msme_no" },
  ]);

  useEffect(() => {
    let today = new Date();
    let year;
    if (today.getMonth() < 3) {
      year = today.getFullYear() - 1 + "-" + today.getFullYear();
    } else {
      year = today.getFullYear() + "-" + (today.getFullYear() + 1);
    }
    setFiYear(year);
    getItClient(year);
  }, []);

  const [isStatus, setIsStatus] = useState([
    { id: 0, name: "UnCompleted", checked: false },
    { id: 1, name: "Completed", checked: false },
  ]);

  const renderCheckBox = (row, key, filtertype) => {
    return (
      <Form key={filtertype + "-" + key}>
        {["checkbox"].map((type) => (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              marginRight: "15px",
            }}
            key={`${filtertype}-${row.id}`}
          >
            <Form.Check
              style={{ display: "flex", alignItems: "center" }}
              type={type}
              checked={row.checked}
              id={`${filtertype}-${row.id}`}
              label={row.name}
              onChange={(e) => {
                let items = [];
                if (filtertype === "Status") {
                  items = [...isStatus];
                  items[key].checked = e.target.checked;
                  setIsStatus(items);
                }
              }}
            />
          </div>
        ))}
      </Form>
    );
  };
  useEffect(() => {
    let filteredData = [...allClientList];
    const searchFilters = selectedColumns.filter((col) => col.search);
    if (searchFilters.length > 0) {
      filteredData = filteredData.filter((client) => {
        return searchFilters.every((col) => {
          const value = client[col.value]?.toString().toLowerCase() || "";
          return value.includes(col.search.toLowerCase());
        });
      });
    }
    const activeStatuses = isStatus
      .filter((status) => status.checked)
      .map((status) => status.id);
    if (activeStatuses.length > 0) {
      filteredData = filteredData.filter((client) =>
        activeStatuses.includes(client.status)
      );
    }
    if (selectedTags.length > 0) {
      filteredData = filteredData.filter((client) => {
        return selectedTags.some((tag) =>
          client.client_tags.some((clientTag) => clientTag.id === tag.value)
        );
      });
    }
    filteredData.sort((a, b) => {
      if (a.is_complete === 1 && b.is_complete !== 1) {
        return 1;
      } else if (a.is_complete !== 1 && b.is_complete === 1) {
        return -1; 
      } else {
        return 0; 
      }
    });
    setItClient(filteredData);
  }, [selectedColumns, isStatus, selectedTags, allClientList]);

  // Fetch Data
  const getItClient = (year) => {
    axios.post(API_URL.CLIENT_IEC_RENEWALS, { year: year }).then((res) => {
      if (res.data.status === 1) {
        setItClient(res.data.data);
        setAllClientList(res.data.data);
      } else {
        toast.error(res.data.data, "", {
          autoClose: 3000,
          position: "top-center",
        });
      }
      setLoading(false);
    });
  };

  const hideShowColumns = (e) => {
    setSelectedColumns(e);
  };


  const handleShowNotes = (note) => {
    setCurrentRow(note);
    setShowNotes(true);
  };

  return (
    <>
      <div className="w-100">
        <div className="ctm-border-radius shadow-sm card">
          <div className="card-body">
            <h4 className="title card-title float-left">
              {itClient.length} Records
            </h4>
            <div
              className="tab-pane fade show active"
              id="pills-home"
              role="tabpanel"
              aria-labelledby="pills-home-tab"
            >
              {showColum && (
                <div className="row mb-3">
                  <div className="col">
                    <Select
                      isClearable={false}
                      className="basic-multi-select"
                      placeholder="Select columns which you want to display"
                      isMulti={true}
                      name="columns"
                      options={columns}
                      value={selectedColumns}
                      onChange={(e) => hideShowColumns(e)}
                    />
                  </div>
                </div>
              )}
              <Modal
                show={modalShow}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
              >
                <Modal.Header className="modal-header-badges">
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <div className="title">Filter By Status</div>
                  </div>
                  <RxCross2
                    size={25}
                    onClick={() => {
                      setModalShow(false);
                    }}
                    style={{ cursor: "pointer" }}
                  />
                </Modal.Header>
                <Modal.Body className="filter-modal">
                  {isStatus.map((row, key) => {
                    return (
                      <div key={key}>{renderCheckBox(row, key, "Status")}</div>
                    );
                  })}
                </Modal.Body>
                <Modal.Footer>
                  <button
                    className="btn btn-danger text-white ctm-border-radius float-right mr-2"
                    onClick={() => {
                      let copyData = [...isStatus];
                      copyData.map((res) => {
                        res.checked = false;
                      });
                      setIsStatus(copyData);
                      setFilter(true);
                      setModalShow(false);
                    }}
                  >
                    Clear
                  </button>
                  <button
                    className="btn btn-theme button-1 text-white ctm-border-radius p-2 add-person ctm-btn-padding"
                    onClick={() => {
                      setFilter(true);
                      setModalShow(false);
                    }}
                  >
                    Apply
                  </button>
                </Modal.Footer>
              </Modal>
              <div className="employee-office-table">
                {loading ? (
                  <Oval
                    height={60}
                    width={60}
                    color="#7539ff"
                    wrapperStyle={{ justifyContent: "center" }}
                    wrapperClass=""
                    visible={true}
                    ariaLabel="oval-loading"
                    secondaryColor="#7539ff"
                    strokeWidth={2}
                    strokeWidthSecondary={2}
                  />
                ) : (
                  <div>
                    <table className="table custom-table table-bordered">
                      <thead>
                        <tr>
                          {selectedColumns.map((row, kd) => {
                            return <th key={kd}>{row.label}</th>;
                          })}
                          <th>Status</th>
                          <th>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                              }}
                            >
                              <div>Action</div>
                              <div>
                                {" "}
                                <CiViewColumn
                                  style={{ textAlign: "end" }}
                                  color="white"
                                  cursor={"pointer"}
                                  size={22}
                                  onClick={() => setShowColum(!showColum)}
                                />
                              </div>
                            </div>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          {selectedColumns.map((row, kd) => {
                            return (
                              <td key={kd}>
                                <input
                                  placeholder="Search..."
                                  type="text"
                                  style={{
                                    width: "100%",
                                    minWidth: "100px",
                                    padding: "0px 8px",
                                  }}
                                  className="form-control form-control-sm"
                                  name="name"
                                  value={row.search}
                                  onChange={(e) => {
                                    let copyData = [...selectedColumns];
                                    copyData[kd].search = e.target.value;
                                    setSelectedColumns(copyData);
                                  }}
                                />
                              </td>
                            );
                          })}
                          <td className="text-center">
                            <FiFilter
                              color={
                                isStatus.filter((d) => d.checked).length > 0
                                  ? "blue"
                                  : "black"
                              }
                              cursor={"pointer"}
                              size={22}
                              onClick={() => setModalShow(true)}
                            />
                          </td>
                          <td className="text-center">
                            <button
                              type="button"
                              className="btn btn-sm btn-red btn-action-table"
                              onClick={() => {
                                setIsShow(!isShow);
                              }}
                            >
                              <AiOutlineTag size={25} />
                            </button>
                          </td>
                        </tr>
                        {itClient.map((res, key) => {
                          return (
                            <tr key={key}>
                              {selectedColumns.map((row, lk) => {
                                return (
                                  <td key={key + "" + lk}>
                                    {row.value === "firm_name" ? (
                                      <>
                                        {res[row.value]}
                                        <div>
                                          {res.client_tags.map((el, tk) => {
                                            return (
                                              <span
                                                key={tk}
                                                style={{
                                                  backgroundColor: "#511989",
                                                }}
                                                className="badge badge-primary mr-1"
                                              >
                                                {el.name}
                                              </span>
                                            );
                                          })}
                                        </div>
                                      </>
                                    ) : (
                                      <>{res[row.value]}</>
                                    )}
                                  </td>
                                );
                              })}
                              <td>
                                <div
                                  className="col form-group pl-0 pr-0 ml-0 mr-0 mb-0 "
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  {res.is_complete == 1 ? (
                                    <label style={{ color: "green" }}>
                                      Completed
                                    </label>
                                  ) : (
                                    <label style={{ color: "red" }}>
                                      Pending
                                    </label>
                                  )}
                                </div>
                              </td>
                              <td>
                                <div className="action-section">
                                  <button
                                    type="button"
                                    className="btn btn-sm btn-red btn-action-table"
                                    onClick={() => {
                                      handleShowNotes(res);
                                    }}
                                  >
                                    <GrNotes size={22} />
                                  </button>
                                  {res.is_complete == 0 &&
                                     <button
                                    type="button"
                                    className="btn btn-sm btn-red btn-action-table "
                                    onClick={() => {
                                      setCurrentRow(res);
                                      setShowTaskModal(true);
                                    }}
                                  >
                                    <FaTasks size={22} />
                                  </button>}
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                )}
                <TagFilter
                  open={isShow}
                  onClose={(tags) => {
                    setIsShow(false);
                    if (tags) {
                      setSelectedTags(tags);
                    }
                  }}
                ></TagFilter>
                <NoteModal
                  firmType={"IEC"}
                  openNotes={shownotes}
                  note={currentRow}
                  onCloseNotes={() => setShowNotes(false)}
                ></NoteModal>
                <TaskModal
                  open={showTaskModal}
                  row={{
                    client_id: currentRow.id,
                    lookup_id: 11,
                    lookup: {
                      key_name: "IEC Renewals",
                    },
                    status:0,
                    client: {
                      firm_name: currentRow.name,
                      person_one_no: currentRow.person_one_no,
                    },
                  }}
                  onCloseModal={() => setShowTaskModal(false)}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default IEC;
