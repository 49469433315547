import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { RxCross2 } from 'react-icons/rx';
import { API_URL } from '../../const';
import Select from 'react-select'
import axios from 'axios';

const FilterByTag = ({ open, onClose = () => { } }) => {

    const [show, setShow] = useState(false);
    const [tags, setTags] = useState([]);
    const [tagList, setTagsList] = useState([]);

    const getData = () => {
        axios.post(API_URL.TAG_LIST, {}).then((res) => {
            setTagsList(res.data.data);
            let filterTagList = res.data.data.map((element) => {
                return {
                    label: element.name,
                    value: element.id,
                }
            });
            setTagsList(filterTagList);
        });
    };

    useEffect(() => {
        if (open) {
            getData();
        }
        open ? setShow(true) : setShow(false);
    }, [open]);

    useEffect(() => {
        // getData();
    }, []);


    return (
        <Modal
            size='md'
            show={show}
            onHide={() => { setShow(false); onClose(); }}
            animation={true}
            centered
        >
            <Modal.Header className='modal-header-badges'>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <div className='title'>Filter By Tags</div>
                </div>
                <RxCross2 size={25} onClick={() => { setShow(false); onClose() }} style={{ cursor: 'pointer' }} />
            </Modal.Header>
            <Modal.Body>
                <Select
                    isClearable={false}
                    isMulti={true}
                    value={tags}
                    styles={{
                        control: (baseStyles, state) => ({
                            ...baseStyles,
                            fontSize: 14,
                            fontWeight: 600
                        }),
                    }}
                    options={tagList}
                    onChange={(e) => {
                        setTags(e);
                    }}
                />
            </Modal.Body>
            <Modal.Footer>
                <button className='btn btn-danger text-white ctm-border-radius float-right mr-2'
                    onClick={() => {
                        setTags([]);
                        setShow(false);
                        onClose([]);
                    }}
                >
                    Clear
                </button>
                <button className='btn btn-theme button-1 text-white ctm-border-radius p-2 add-person ctm-btn-padding'
                    onClick={() => {
                        setShow(false);
                        onClose(tags);
                    }}
                >
                    Apply
                </button>
            </Modal.Footer>
        </Modal >
    )
}

export default FilterByTag
