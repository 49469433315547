import React, { useState, useEffect } from "react";
import axios from "axios";
import { API_URL } from "../../const";
import { toast } from "react-toastify";
import { Oval } from "react-loader-spinner";
import { Watch } from "react-loader-spinner";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";

const NonRoutineTask = () => {
  const [loading, setLoading] = useState(true);
  const [groupList, setGroupList] = useState([]);
  const [isLoading, setIsLoading] = useState({});
  const [isEdit, setIsEdit] = useState(false);
  const [formData, setFormData] = useState({
    id: 0,
    key_name: "",
    key_type: "",
    kay_value: "",
  });
  const Typeoptions = [
    { name: "Non Routing", code: "Non Routing" },
    { name: "Routing", code: "Routing" },
  ];
  const generateTask = (lookup_id) => {
    setIsLoading((prev) => ({ ...prev, [lookup_id]: true }));
    axios
      .post(API_URL.TASK_GENERATE, { lookup_id: lookup_id })
      .then((response) => {
        if (response.data.status === 1) {
          toast.success("Task generated successfully!", {
            autoClose: 3000,
            position: "top-center",
          });
        } else {
          toast.error("Failed to generate task.", {
            autoClose: 3000,
            position: "top-center",
          });
        }
      })
      .catch((error) => {
        toast.error("Something went wrong..", {
          autoClose: 3000,
          position: "top-center",
        });
      })
      .finally(() => {
        setIsLoading((prev) => ({ ...prev, [lookup_id]: false }));
      });
  };

  const onSaveData = () => {
    let url = API_URL.LOOKUP_SAVE;
    setIsEdit(false);
    let body = { ...formData };
    axios.post(url, body).then((response) => {
      if (response.data.status === 1) {
        getData();
        setFormData({
          id: 0,
          key_name: "",
          key_type: "",
          kay_value: "",
        });
      } else {
        toast.error("Something went wrong..", {
          autoClose: 3000,
          position: "top-center",
        });
      }
    });
  };

  const getData = () => {
    axios.post(API_URL.LOOKUP_LIST).then(
      (res) => {
        if (res.data.status === 1) {
          setGroupList(res.data.data);
        }
        setLoading(false);
      },
      (error) => {
        toast.error("Something went wrong..", {
          autoClose: 3000,
          position: "top-center",
        });
        setLoading(false);
      }
    );
  };

  const isValidForm = () => {
    return formData.key_name && formData.kay_value;
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div className="row">
      <div className="col-md-4">
        <div className="card">
          <div className="card-body">
            <form onSubmit={(e) => e.preventDefault()}>
              <div className="form-group edit-name">
                <label htmlFor="Type" className="form-label">
                  Type
                </label>
                <Dropdown
                  inputId="Type"
                  value={Typeoptions.find((d) => d.code === formData.key_type)}
                  onChange={(e) => {
                    debugger;
                    setFormData({ ...formData, key_type: e.value.code });
                  }}
                  placeholder="Select a Type..."
                  options={Typeoptions}
                  optionLabel="name"
                />
              </div>
              <div className="form-group edit-name">
                <label htmlFor="key_name" className="form-label">
                  Name
                </label>
                <InputText
                  inputId="key_name"
                  value={formData.key_name}
                  onChange={(e) => {
                    setFormData({ ...formData, key_name: e.target.value });
                  }}
                />
              </div>
              <div className="form-group edit-name">
                <label htmlFor="kay_value" className="form-label">
                  Value
                </label>
                <InputText
                  inputId="kay_value"
                  value={formData.kay_value}
                  onChange={(e) => {
                    setFormData({ ...formData, kay_value: e.target.value });
                  }}
                />
              </div>
              <div className="col-md-12 mt-3 p-0">
                <button
                  type="button"
                  className="btn btn-theme ctm-border-radius text-white float-right"
                  aria-hidden="true"
                  onClick={onSaveData}
                  disabled={!isValidForm()}
                >
                  Create
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div className="col-md-8">
        <div className="card">
          <div className="card-body">
            {loading ? (
              <Oval
                height={60}
                width={60}
                color="#7539ff"
                wrapperStyle={{ justifyContent: "center" }}
                wrapperClass=""
                visible={true}
                ariaLabel="oval-loading"
                secondaryColor="#7539ff"
                strokeWidth={2}
                strokeWidthSecondary={2}
              />
            ) : (
              <div className="table-responsive">
                <table className="table custom-table table-bordered">
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Value</th>
                      <th>Type</th>
                      <th style={{ width: "130px" }} className="text-center">
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {groupList
                      .sort((a, b) => {
                        if (
                          a.key_type === "Routing" &&
                          b.key_type !== "Routing"
                        )
                          return -1;
                        if (
                          a.key_type !== "Routing" &&
                          b.key_type === "Routing"
                        )
                          return 1;
                        return 0;
                      })
                      .map((res, key) => {
                        return (
                          <tr key={key}>
                            <td>{res.key_name}</td>
                            <td>{res.key_type}</td>
                            <td>{res.kay_value}</td>
                            <td>
                              {res.key_type === "Routing" && res.kay_value !== "TDS" &&  res.kay_value !== "GST Return" && res.kay_value !== "None" &&(
                                <button
                                  type="button"
                                  className="d-flex btn btn-theme ctm-border-radius text-white p-1"
                                  onClick={() => generateTask(res.id)}
                                  disabled={isLoading[res.id]}
                                >
                                  {isLoading[res.id] && (
                                    <div
                                      style={{
                                        marginRight: "10px",
                                        marginTop: "2px",
                                      }}
                                    >
                                      <Watch
                                        color={"white"}
                                        height={20}
                                        width={20}
                                      />
                                    </div>
                                  )}
                                  Generate
                                </button>
                              )}
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default NonRoutineTask;
