import React, { useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { API_URL } from "../../const";
import { toast } from "react-toastify";
import { ImCross } from "react-icons/im";
import { RxCross2 } from "react-icons/rx";
import Swal from "sweetalert2";
import moment from "moment";
import axios from "axios";
import RichTextEditor from "react-rte";
import Select from "react-select";
import DatePicker from "react-datepicker";

const NoteModal = ({ openNotes, note, onCloseNotes, firmType }) => {
  const [employeeList, setEmployeeList] = useState([]);
  const [shownotes, setShowNotes] = useState(false);
  const [noteList, setNoteList] = useState([]);
  const [formData, setFormData] = useState({
    client_id: note.id,
    firm_type: firmType,
    note_type: "",
    tag_by: null,
    date: null,
    notes: RichTextEditor.createEmptyValue(),
  });

  const getEmployee = () => {
    axios.post(API_URL.EMP_LIST, {}).then((res) => {
      let employess = [];
      res.data.data.map((element) => {
        employess.push({ value: element.name, label: element.name });
      });
      setEmployeeList(employess);
    });
  };

  const listData = () => {
    axios
      .post(API_URL.NOTES_LIST, { client_id: note.id, firm_type: firmType })
      .then(
        (res) => {
          if (res.data.status === 1) {
            res.data.data.map((element) => {
              element["date"] = element.date ? new Date(element.date) : null;
            });
            setNoteList(res.data.data);
          }
        },
        (error) => {
          toast.error("Something went wrong..", {
            autoClose: 3000,
            position: "top-center",
          });
        }
      );
  };

  const saveData = (e) => {
    let url = API_URL.NOTES_SAVE;
    let body = { ...formData };
    body.date = formData.date
      ? moment(formData.date).format("YYYY-MM-DD")
      : null;
    body.notes = body.notes.toString("html");
    if (body.note_type === "") {
      toast.error("Note type must be required !", {
        autoClose: 3000,
        position: "top-center",
      });
      return false;
    }
    body.note_type = body.note_type.value;
    body.tag_by = body.tag_by.value ? body.tag_by.value : null;
    axios.post(url, body).then((response) => {
      if (response.data.status === 1) {
        setFormData((prevState) => ({
          ...prevState,
          notes: RichTextEditor.createEmptyValue(),
          note_type: "",
          tag_by: null,
          date: null,
        }));
        listData();
        toast.success("Notes Save Successfully", {
          autoClose: 3000,
          position: "top-center",
        });
      } else {
        if (response.data && response.data.data) {
          toast.error("something Went Wromg.....", {
            autoClose: 3000,
            position: "top-center",
          });
        }
      }
    });
  };

  const updateReminderDate = (id, date) => {
    let url = API_URL.NOTES_UPDATE;
    axios
      .post(url, {
        id: id,
        date: date ? moment(date).format("YYYY-MM-DD") : null,
      })
      .then((response) => {
        if (response.data.status === 1) {
          listData();
        } else {
          if (response.data && response.data.data) {
            toast.error("something Went Wromg.....", {
              autoClose: 3000,
              position: "top-center",
            });
          }
        }
      });
  };

  const deleteData = (row) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to delete this note",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085D6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        axios.post(API_URL.NOTES_DELETE, { id: row.id }).then((res) => {
          if (res.data.status === 1) {
            listData();
            toast.success("Delete Successfully", {
              autoClose: 3000,
              position: "top-center",
            });
          } else {
            toast.error(res.data.data, {
              autoClose: 3000,
              position: "top-center",
            });
          }
        });
      }
    });
  };

  useEffect(() => {
    if (openNotes) {
      listData();
      getEmployee();
    }
    openNotes ? setShowNotes(true) : setShowNotes(false);
  }, [openNotes]);

  useEffect(() => {
    if (note) {
      setFormData({ ...formData, client_id: note.id });
    }
  }, [note]);

  // useEffect(() => {
  //   listData();
  //   getEmployee();
  // }, [openNotes, note]);

  const options = [
    { value: "Document Pending", label: "Document Pending", color: "Red" },
    { value: "Suspense", label: "Suspense", color: "#010b13" },
    { value: "Our Side Pending", label: "Our Side Pending", color: "blue" },
    { value: "Payment", label: "Payment", color: "Green" },
    { value: "Emergency Complate", label: "Emergency Complate", color: "#eaa221"},
    { value: "Others", label: "Others", color: "#ff1493" },
  ];

  const styles = {
    menu: (basestyle, state) => ({
      ...basestyle,
      zIndex: 999,
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: "white",
        color: "#000",
      };
    },
  };

  const toolbarConfig = {
    display: ["INLINE_STYLE_BUTTONS", "BLOCK_TYPE_BUTTONS"],
    INLINE_STYLE_BUTTONS: [
      { label: "Bold", style: "BOLD", className: "custom-css-class" },
      { label: "Italic", style: "ITALIC" },
      { label: "Underline", style: "UNDERLINE" },
    ],

    BLOCK_TYPE_BUTTONS: [
      { label: "UL", style: "unordered-list-item" },
      { label: "OL", style: "ordered-list-item" },
    ],
  };

  return (
    <Modal
      scrollable={true}
      show={shownotes}
      size="xl"
      onHide={() => {
        onCloseNotes();
        setShowNotes(false);
      }}
      animation={true}
      centered
    >
      <Modal.Header className="modal-header-badges">
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <span className="badge badge-secondary">{note.file_no}</span>
          <div className="title ms-2">{note.name} Notes</div>
        </div>
        <RxCross2
          size={25}
          onClick={() => {
            onCloseNotes();
            setShowNotes(false);
          }}
          style={{ cursor: "pointer" }}
        />
      </Modal.Header>
      <Modal.Body style={{ minHeight: "400px" }}>
        <div className="row note-modal">
          <div className="col-md-6">
            <form>
              <div className="row">
                <div className="col-md-6 pr-0">
                  <label className="mb-0 form-label">Status</label>
                  <Select
                    className="basic-single mb-3"
                    name="color"
                    styles={styles}
                    options={options}
                    placeholder="Select Type.."
                    value={formData.note_type}
                    onChange={(e) => setFormData({ ...formData, note_type: e })}
                  />
                </div>
                <div className="col-md-6">
                  <label className="mb-0 form-label">Reminder Date</label>
                  <div className="form-group">
                    <DatePicker
                      dateFormat="dd/MM/yyyy"
                      showIcon
                      className="form-control"
                      placeholderText="Select Date.."
                      selected={formData.date}
                      onChange={(date) => {
                        setFormData((prevState) => ({
                          ...prevState,
                          date: date,
                        }));
                      }}
                    />
                  </div>
                </div>
                <div className="col-md-6 pr-0">
                  <label className="mb-0 form-label">Tag By</label>
                  <Select
                    className="basic-single mb-3"
                    name="color"
                    styles={styles}
                    options={employeeList}
                    placeholder="Select Employee.."
                    value={formData.tag_by}
                    onChange={(e) => setFormData({ ...formData, tag_by: e })}
                  />
                </div>
              </div>
              <RichTextEditor
                toolbarConfig={toolbarConfig}
                value={formData.notes}
                placeholder="Write your note here...."
                onChange={(e) => {
                  setFormData({ ...formData, notes: e });
                }}
              />
              <button
                type="button"
                onClick={saveData}
                className="btn btn-theme ctm-border-radius text-white float-right mt-2"
              >
                Save
              </button>
            </form>
          </div>
          <div
            className="col-md-6"
            style={{ height: "450px", overflowY: "scroll" }}
          >
            {noteList.map((res, ke) => {
              return (
                <div style={{ position: "relative" }} key={ke}>
                  <div className="note-type">{res.note_type}</div>
                  <div className="note-created-date">
                    {moment(res.created_at).format("DD/MM/YYYY hh:mm A")}
                  </div>
                  <div className="note-section">
                    <div className="box">
                      <div className="user-name">
                        Created By{" "}
                        <span style={{ color: "#3E007C " }}>{res.user}{res.tag_by &&<>,</>}</span>
                       {res.tag_by && <>
                        <span>{" "}Tag By{" "}</span>
                        <span style={{ color: "#3E007C " }}>{res.tag_by}</span>
                        </>}
                      </div>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <div className="date" style={{ cursor: "pointer" }}>
                          <DatePicker
                            dateFormat="dd/MM/yyyy"
                            placeholderText="Select Date.."
                            selected={res.date}
                            disabled={res.status === 1}
                            customInput={
                              <div
                                className={`date ${
                                  res.status === 1 && "close-remindar"
                                }`}
                              >
                                {res.date ? (
                                  <>
                                    Reminder:{" "}
                                    {moment(res.date).format("DD/MM/YYYY")}{" "}
                                  </>
                                ) : (
                                  <div style={{ color: "blue" }}>
                                    Set Reminder
                                  </div>
                                )}
                              </div>
                            }
                            onChange={(date) => {
                              const copyData = [...noteList];
                              copyData[ke].date = date;
                              setNoteList(copyData);
                              updateReminderDate(res.id, date);
                            }}
                          />
                        </div>
                        <ImCross
                          size={10}
                          onClick={() => deleteData(res)}
                          style={{ cursor: "pointer" }}
                        />
                      </div>
                    </div>
                    <div className="detail">
                      <div
                        className="title"
                        dangerouslySetInnerHTML={{ __html: res.notes }}
                      ></div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer></Modal.Footer>
    </Modal>
  );
};

export default NoteModal;
