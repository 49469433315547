import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import "../../App.css";
import "react-datepicker/dist/react-datepicker.css";
import Accounting from "./accounting";
import CC from "../index/cc";
import GstReturn from "../index/gst-return";
import Gstr1 from "../index/gstr1";
import Gstr3b from "../index/gstr3b";
import GSTR9 from "../index/gstr9";
import GSTR9C from "../index/gstr9c";
import IEC from "../index/iec";
import ItAudit from "../index/itaudit";
import TDS from "../index/tds";
import TrustAudit from "../index/trustAudit";
import ItIndex from "../index/it-index";
import LLP from "../index/llpAudit";

const FirmIndex = () => {
  const [mode, setMode] = useState(1);
  const location = useLocation();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const modeParam = queryParams.get("mode");
    if (modeParam) {
      setMode(parseInt(modeParam));
    }
  }, [location.search]);

  const handleModeChange = (newMode) => {
    setMode(newMode);
    const queryParams = new URLSearchParams(location.search);
    queryParams.set("mode", newMode);
    window.history.pushState(
      {},
      "",
      `${location.pathname}?${queryParams.toString()}`
    );
  };
  return (
    <>
      <div className="w-100">
        <div className="card shadow-sm ctm-border-radius">
          <div className="card-body">
            <div
              className="flex-row list-group list-group-horizontal-lg"
              id="v-pills-tab"
              role="tablist"
              aria-orientation="vertical"
            >
              <a
                className={mode === 1 ? "active list-group-item" : "list-group-item findex"}
                id="v-pills-home-tab"
                data-toggle="pill"
                href="reviews.html#v-pills-home"
                role="tab"
                aria-controls="v-pills-home"
                aria-selected={mode === 1}
                onClick={() => handleModeChange(1)}
              >
                IT Return
              </a>
              <a
                className={mode === 2 ? "active list-group-item" : "list-group-item findex"}
                id="v-pills-profile-tab"
                data-toggle="pill"
                href="reviews.html#v-pills-profile"
                role="tab"
                aria-controls="v-pills-profile"
                aria-selected={mode === 2}
                onClick={() => handleModeChange(2)}
              >
                Accounting
              </a>
              <a
                className={mode === 3 ? "active list-group-item" : "list-group-item findex"}
                id="v-pills-profile-tab"
                data-toggle="pill"
                href="reviews.html#v-pills-profile"
                role="tab"
                aria-controls="v-pills-profile"
                aria-selected={mode === 3}
                onClick={() => handleModeChange(3)}
              >
                IT Audit
              </a>
              <a
                className={mode === 7 ? "active list-group-item" : "list-group-item findex"}
                id="v-pills-profile-tab"
                data-toggle="pill"
                href="reviews.html#v-pills-profile"
                role="tab"
                aria-controls="v-pills-profile"
                aria-selected={mode === 7}
                onClick={() => handleModeChange(7)}
              >
                Trust Audit
              </a>
              {/* <a
                className={mode === 4 ? "active list-group-item" : "list-group-item findex"}
                id="v-pills-profile-tab"
                data-toggle="pill"
                href="reviews.html#v-pills-profile"
                role="tab"
                aria-controls="v-pills-profile"
                aria-selected={mode === 4}
                onClick={() => handleModeChange(4)}
              >
                GST Return
              </a> */}
              <a
                className={mode === 12 ? "active list-group-item" : "list-group-item findex"}
                id="v-pills-profile-tab"
                data-toggle="pill"
                href="reviews.html#v-pills-profile"
                role="tab"
                aria-controls="v-pills-profile"
                aria-selected={mode === 12}
                onClick={() => handleModeChange(12)}
              >
                GSTR 1
              </a>
              <a
                className={mode === 13 ? "active list-group-item" : "list-group-item findex"}
                id="v-pills-profile-tab"
                data-toggle="pill"
                href="reviews.html#v-pills-profile"
                role="tab"
                aria-controls="v-pills-profile"
                aria-selected={mode === 13}
                onClick={() => handleModeChange(13)}
              >
                GSTR 3B
              </a>
              <a
                className={mode === 5 ? "active list-group-item" : "list-group-item findex"}
                id="v-pills-profile-tab"
                data-toggle="pill"
                href="reviews.html#v-pills-profile"
                role="tab"
                aria-controls="v-pills-profile"
                aria-selected={mode === 5}
                onClick={() => handleModeChange(5)}
              >
                GSTR9
              </a>
              <a
                className={mode === 6 ? "active list-group-item" : "list-group-item findex"}
                id="v-pills-profile-tab"
                data-toggle="pill"
                href="reviews.html#v-pills-profile"
                role="tab"
                aria-controls="v-pills-profile"
                aria-selected={mode === 6}
                onClick={() => handleModeChange(6)}
              >
                GSTR9C
              </a>
              <a
                className={mode === 8 ? "active list-group-item" : "list-group-item findex"}
                id="v-pills-profile-tab"
                data-toggle="pill"
                href="reviews.html#v-pills-profile"
                role="tab"
                aria-controls="v-pills-profile"
                aria-selected={mode === 8}
                onClick={() => handleModeChange(8)}
              >
                TDS
              </a>
              <a
                className={mode === 9 ? "active list-group-item" : "list-group-item findex"}
                id="v-pills-profile-tab"
                data-toggle="pill"
                href="reviews.html#v-pills-profile"
                role="tab"
                aria-controls="v-pills-profile"
                aria-selected={mode === 9}
                onClick={() => handleModeChange(9)}
              >
                CC Renewals
              </a>
              <a
                className={mode === 10 ? "active list-group-item" : "list-group-item findex"}
                id="v-pills-profile-tab"
                data-toggle="pill"
                href="reviews.html#v-pills-profile"
                role="tab"
                aria-controls="v-pills-profile"
                aria-selected={mode === 10}
                onClick={() => handleModeChange(10)}
              >
                IEC Renewals
              </a>
              <a
                className={mode === 11 ? "active list-group-item" : "list-group-item findex"}
                id="v-pills-profile-tab"
                data-toggle="pill"
                href="reviews.html#v-pills-profile"
                role="tab"
                aria-controls="v-pills-profile"
                aria-selected={mode === 11}
                onClick={() => handleModeChange(11)}
              >
                LLP Audit
              </a>
            </div>
          </div>
        </div>
        {mode === 1 && <ItIndex />}
        {mode === 2 && <Accounting />}
        {mode === 3 && <ItAudit />}
        {mode === 7 && <TrustAudit />}
        {/* {mode === 4 && <GstReturn />} */}
        {mode === 12 && <Gstr1 />}
        {mode === 13 && <Gstr3b />}
        {mode === 5 && <GSTR9 />}
        {mode === 6 && <GSTR9C />}
        {mode === 8 && <TDS />}
        {mode === 9 && <CC />}
        {mode === 10 && <IEC />}
        {mode === 11 && <LLP />}
      </div>
    </>
  );
};

export default FirmIndex;
