import React, { useEffect, useState } from 'react';
import { API_URL } from '../../const';
import { toast } from 'react-toastify';
import { Oval } from "react-loader-spinner";
import axios from 'axios';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Swal from "sweetalert2";
import { BiEdit } from 'react-icons/bi';
import { AiOutlineDelete } from 'react-icons/ai';


const Employee = () => {

    const [lgShow, setLgShow] = useState(false);
    const [hide, setHide] = useState("");
    const [loading, setLoading] = useState(true);
    const [validated, setValidated] = useState(false);
    const [employeeList, setEmployeeList] = useState([]);
    const [action, setAction] = useState("");

    const [employeeData, setEmployeeData] = useState({
        id: "",
        name: "",
        email: "",
        phone_number: "",
        job_title: "",
        password: "",
        password_confirmation: "",
    })

    useEffect(() => {
        getEmployee();
    }, []);

    // Fetch Data
    const getEmployee = () => {
        axios.post(API_URL.EMP_LIST, {}).then(res => {
            setEmployeeList(res.data.data);
            setLoading(false);
        });
    }

    // Delete Data
    const deleteEmployee = (res) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                axios.post(API_URL.EMP_DELETE, { id: res.id }).then(res => {
                    if (res.data.status === 1) {
                        getEmployee();
                        toast.success('Successfully Employee Deleted', {
                            autoClose: 3000,
                            position: "top-center",
                        });
                    }
                    setLoading(false);
                });
            }
        })
    }

    // Add Data
    const handleOpenAddEmployee = () => {
        setLgShow(true);
        setHide("password");
        setAction("Add");
        setEmployeeData({
            id: "",
            name: "",
            email: "",
            phone_number: "",
            job_title: "",
            password: "",
            password_confirmation: "",
        })
    }

    // Edit Data
    const editEmployee = (res) => {
        setLgShow(true)
        setHide("hidden");
        setAction("Edit");
        setEmployeeData({
            id: res.id,
            name: res.name,
            email: res.email,
            phone_number: res.phone_number,
            job_title: res.job_title,
        })
    }

    const saveEmployee = (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }
        else {
            setValidated(true);
        }

        if (action === 'Edit') {
            axios.post(API_URL.EMP_UPDATE, {
                id: employeeData.id,
                name: employeeData.name,
                email: employeeData.email,
                phone_number: employeeData.phone_number,
                job_title: employeeData.job_title
            }).then(res => {
                if (res.data.status === 1) {
                    getEmployee();
                    toast.success('Successfully Edited', {
                        autoClose: 3000,
                        position: "top-center",
                    });
                }
                setLoading(false);
                setLgShow(false)
            });
            getEmployee();
        }
        else {
            if (employeeData.name && employeeData.email && employeeData.phone_number && employeeData.job_title && (employeeData.password === employeeData.password_confirmation)) {
                axios.post(API_URL.EMP_SAVE, employeeData).then(res => {
                    if (res.data.status === 1) {

                    } else {
                        toast.error(res.data.data, {
                            autoClose: 3000,
                            position: "top-center",
                        });
                    }
                    setLgShow(false)
                });
                toast.success('Successfully Employee Added', {
                    autoClose: 3000,
                    position: "top-center",
                });
                getEmployee();
            } else {
                toast.error('Please enter valid details', {
                    autoClose: 3000,
                    position: "top-center",
                });
            }
        }
    }

    return (
        <div className="w-100">
            <div className="ctm-border-radius shadow-sm card">
                <div className="card-body">
                    <div className='d-flex justify-content-space-between' style={{ marginBottom: '5px', alignItems: 'center' }}>
                        <h4 className="title">{employeeList.length} Records</h4>
                        <button id="modal-2" className="btn btn-theme button-1 text-white ctm-border-radius p-2 add-person ctm-btn-padding" onClick={handleOpenAddEmployee}><i className="fa fa-plus"></i> Add New</button>
                    </div>
                    <div>
                        {loading ?
                            <Oval
                                height={60}
                                width={60}
                                color="#7539ff"
                                wrapperStyle={{ justifyContent: "center" }}
                                wrapperClass=""
                                visible={true}
                                ariaLabel="oval-loading"
                                secondaryColor="#7539ff"
                                strokeWidth={2}
                                strokeWidthSecondary={2}
                            />
                            :
                            <div className="table-responsive">
                                <table className="table custom-table table-bordered">
                                    <thead>
                                        <tr>
                                            <th style={{ width: '25%' }}>Name</th>
                                            <th style={{ width: '20%' }}>Email</th>
                                            <th style={{ width: '20%' }}>Mobile Number</th>
                                            <th style={{ width: '20%' }}>Job Title </th>
                                            <th className='text-center' style={{ width: '15%' }}>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {employeeList.map((res, key) => {
                                            return (
                                                <tr key={key}>
                                                    <td>{res.name}</td>
                                                    <td>{res.email}</td>
                                                    <td>{res.phone_number}</td>
                                                    <td>{res.job_title}</td>
                                                    <td>
                                                        <div className="action-section">
                                                            <button type="button" className="btn btn-sm btn-green btn-action-table" onClick={() => { editEmployee(res); }}><BiEdit size={25} /></button>
                                                            <button type="button" className="btn btn-sm btn-red btn-action-table " onClick={() => deleteEmployee(res)}><AiOutlineDelete size={25} /></button>
                                                        </div>
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        }
                    </div>
                </div>
            </div>
            <Modal
                backdrop="static"
                size="lg"
                show={lgShow}
                centered
                onHide={() => setLgShow(false)}
                aria-labelledby="example-modal-sizes-title-lg"
            >
                <Modal.Header>
                    <Modal.Title id="example-modal-sizes-title-lg">
                        {action} Employee
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {
                        loading ?
                            <Oval
                                height={60}
                                width={60}
                                color="#7539ff"
                                wrapperStyle={{ justifyContent: "center" }}
                                wrapperClass=""
                                visible={true}
                                ariaLabel="oval-loading"
                                secondaryColor="#7539ff"
                                strokeWidth={2}
                                strokeWidthSecondary={2}
                            /> :
                            <div className="page-wrapper" >
                                <div className="container-fluid">
                                    <div className="row">
                                        <Form noValidate validated={validated} >
                                            <div className="col-xl-12 col-lg-12 col-md-12 mb-4">
                                                <div className="accordion add-employee" id="accordion-details">
                                                    <div className="needs-validation form-row mt-5">
                                                        <div className="row">
                                                            <div className="col-6 form-group edit-name">
                                                                <label htmlFor="validationCustom01">Name</label>
                                                                <input type="text" className="form-control" id="name" value={employeeData.name} onChange={(e) => { setEmployeeData({ ...employeeData, name: e.target.value }) }} required />
                                                            </div>
                                                            <div className="col-6 form-group edit-name">
                                                                <label htmlFor="validationCustom01">Email</label>
                                                                <input type="email" className="form-control" id="email" value={employeeData.email} onChange={(e) => { setEmployeeData({ ...employeeData, email: e.target.value }) }} required />
                                                            </div>
                                                            <div className="col-6 form-group edit-name">
                                                                <label htmlFor="validationCustom01">Mobile Number</label>
                                                                <input type="text" className="form-control" id="phone_number" maxLength={10} mask="99999 99999" value={employeeData.phone_number} onChange={(e) => { setEmployeeData({ ...employeeData, phone_number: e.target.value }) }} required />
                                                            </div>
                                                            <div className="col-6 form-group edit-name">
                                                                <label htmlFor="validationCustom01">Job Title</label>
                                                                <input type="text" className="form-control" id="job_title" value={employeeData.job_title} onChange={(e) => { setEmployeeData({ ...employeeData, job_title: e.target.value }) }} required />
                                                            </div>
                                                            {action !== 'Edit' &&
                                                                <div className="col-6 form-group edit-name">
                                                                    <label htmlFor="validationCustom01">Password</label>
                                                                    <input type="password" className="form-control" id="password" value={employeeData.password} onChange={(e) => { setEmployeeData({ ...employeeData, password: e.target.value }) }} required />
                                                                </div>}
                                                            {action !== 'Edit' &&
                                                                <div className="col-6 form-group edit-name">
                                                                    <label htmlFor="validationCustom01">Confirm Password</label>
                                                                    <input type="password" className="form-control" id="password_confirmation" value={employeeData.password_confirmation} onChange={(e) => { setEmployeeData({ ...employeeData, password_confirmation: e.target.value }) }} required />
                                                                </div>}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-xl-12 col-lg-12 col-md-12">
                                                <button type="button" className="btn btn-theme ctm-border-radius text-white float-right" aria-hidden="true" onClick={saveEmployee}>
                                                    {action}
                                                </button>
                                                <button type="button" className="btn btn-danger text-white ctm-border-radius float-right mr-3" onClick={() => setLgShow(false)}>Cancel</button>
                                            </div>
                                        </Form>
                                    </div>
                                </div>
                            </div>
                    }
                </Modal.Body>
            </Modal>
        </div>
    )
}
export default Employee;