import React, { useEffect, useState } from "react";
import { API_URL } from "../../const";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { InputNumber } from "primereact/inputnumber";
import { Button } from "primereact/button";
import axios from "axios";
import { toast } from "react-toastify";

const Task = () => {
  const [formData, setFormData] = useState({
    task: null,
    steps: [
      {
        id: 0,
        what: "",
        how: "",
        who: null,
        when: "",
        videoLink: "",
        documentLink: "",
      },
    ],
  });
  const [taskOption, setTaskOption] = useState([]);
  const [employeeList, setEmployeeList] = useState([]);
  const [isError, setError] = useState(false);
  const [isBusy, setBusy] = useState(false);

  const getEmployee = () => {
    axios.post(API_URL.EMP_LIST, {}).then((res) => {
      setEmployeeList(res.data.data);
    });
  };

  const getTaskData = () => {
    axios.post(API_URL.LOOKUP_LIST).then((res) => {
      if (res.data.status === 1) {
        setTaskOption(res.data.data);
      }
    });
  };

  const addNewRow = () => {
    let copyData = { ...formData };
    copyData.steps.push({
      id: 0,
      what: "",
      how: "",
      who: null,
      when: "",
      videoLink: "",
      documentLink: "",
    });
    setFormData(copyData);
  };

  const removeRow = (index) => {
    let copyData = { ...formData };
    copyData.steps.splice(index, 1);
    setFormData(copyData);
  };

  const isShowError = (value) => {
    if (
      isError &&
      (!value || value == "" || value == null || value == undefined)
    ) {
      return true;
    }
    return false;
  };

  const saveData = () => {
    setBusy(true);
    let isError = false;
    formData.steps.forEach((element) => {
      if (!element.what || !element.how || !element.who || !element.when) {
        isError = true;
      }
    });
    if (isError) {
      setError(true);
    } else {
      let body = {
        lookup_id: formData.task.id,
        tasks: formData.steps.map((task, key) => {
          return {
            id: task.id,
            employee_id: task.who.id,
            for_what: task.what,
            for_how: task.how,
            document_link: task.documentLink,
            video_link: task.videoLink,
            time_duration: task.when,
            step_no: key + 1,
          };
        }),
      };
      axios.post(API_URL.TASK_SAVE, body).then((res) => {
        if (res.data.status === 1) {
          toast.success("Task submitted successfully", {
            autoClose: 3000,
            position: "top-center",
          });
          setBusy(false);
          getStepDetail();
        } else {
          setBusy(false);
        }
      });
    }
  };

  const getStepDetail = () => {
    axios
      .post(API_URL.TASK_SHOW, { lookup_id: formData.task.id })
      .then((res) => {
        let copyData = { ...formData };
        if (res.data.status === 1 && res.data.data.length > 0) {
          let tasks = [];
          res.data.data.map((element) => {
            tasks.push({
              id: element.id,
              who: employeeList.find((d) => d.id === element.employee_id),
              what: element.for_what,
              how: element.for_how,
              documentLink: element.document_link,
              videoLink: element.video_link,
              when: element.time_duration,
            });
          });
          copyData.steps = tasks;
          setFormData(copyData);
        } else {
          let copyData = { ...formData };
          copyData.steps = [];
          setFormData(copyData);
        }
      });
  };

  useEffect(() => {
    getTaskData();
    getEmployee();
  }, []);

  useEffect(() => {
    formData.task && getStepDetail();
  }, [formData.task]);

  return (
    <>
      <div className="w-100">
        <div className="card shadow-sm ctm-border-radius">
          <div className="card-body">
            <div className="row">
              <div className="col-4 form-group">
                <label htmlFor="dd-task" className="form-label">
                  Task
                </label>
                <Dropdown
                  inputId="dd-task"
                  value={formData.task}
                  onChange={(e) => {
                    setFormData((prevStat) => ({
                      ...prevStat,
                      task: e.value,
                    }));
                  }}
                  placeholder="Select a Task..."
                  options={taskOption}
                  optionLabel="key_name"
                  className=""
                />
              </div>
            </div>
            {formData.task && (
              <>
                {formData.steps.map((step, key) => {
                  return (
                    <div className="row p-step-box" key={key}>
                      <div className="col-12 title">
                        <div>Step {key + 1}</div>
                      </div>
                      <div className="col-5 pr-0">
                        <label htmlFor="dd-task" className="form-label">
                          WHAT?
                        </label>
                        <InputText
                          id={"what" + key}
                          value={step.what}
                          onChange={(e) => {
                            let copyData = { ...formData };
                            copyData.steps[key].what = e.target.value;
                            setFormData(copyData);
                          }}
                          placeholder="Enter Step Name"
                          className={`p-inputtext-sm  ${
                            isShowError(step.what) && "p-invalid block"
                          }`}
                        />
                      </div>
                      <div className="col-2 pr-0">
                        <label htmlFor="dd-task" className="form-label">
                          WHO?
                        </label>
                        <Dropdown
                          id={"who" + key}
                          value={step.who}
                          onChange={(e) => {
                            let copyData = { ...formData };
                            copyData.steps[key].who = e.value;
                            setFormData(copyData);
                          }}
                          placeholder="Select an Employee"
                          options={employeeList}
                          optionLabel="name"
                          className={`p-inputtext-sm  ${
                            isShowError(step.who) && "p-invalid block"
                          }`}
                        />
                      </div>
                      <div className="col-1 pr-0">
                        <label htmlFor="dd-task" className="form-label">
                          WHEN?
                        </label>
                        <InputNumber
                          id={"when" + key}
                          value={step.when}
                          onValueChange={(e) => {
                            let copyData = { ...formData };
                            copyData.steps[key].when = e.value;
                            setFormData(copyData);
                          }}
                          useGrouping={false}
                          placeholder="Hours"
                          className={`${
                            isShowError(step.when) && "p-invalid block"
                          }`}
                        />
                      </div>
                      <div className="col-2 pr-0">
                        <label htmlFor="dd-task" className="form-label">
                          Document Link
                        </label>
                        <InputText
                          id="documentLink"
                          value={step.documentLink}
                          onChange={(e) => {
                            let copyData = { ...formData };
                            copyData.steps[key].documentLink = e.target.value;
                            setFormData(copyData);
                          }}
                          placeholder="Add Document Link"
                          className="p-inputtext-sm"
                        />
                      </div>
                      <div className="col-2">
                        <label htmlFor="dd-task" className="form-label">
                          Video Link
                        </label>
                        <InputText
                          id="videoLink"
                          value={step.videoLink}
                          onChange={(e) => {
                            let copyData = { ...formData };
                            copyData.steps[key].videoLink = e.target.value;
                            setFormData(copyData);
                          }}
                          placeholder="Add Video Link"
                          className="p-inputtext-sm"
                        />
                      </div>
                      <div className="col-10 pr-0 mt-1">
                        <label htmlFor="dd-task" className="form-label">
                          How?
                        </label>
                        <InputTextarea
                          id="how"
                          value={step.how}
                          rows={3}
                          onChange={(e) => {
                            let copyData = { ...formData };
                            copyData.steps[key].how = e.target.value;
                            setFormData(copyData);
                          }}
                          placeholder="Enter Description"
                          className={`${
                            isShowError(step.how) && "p-invalid block"
                          }`}
                        />
                      </div>
                      <div className="col-2 text-right d-flex flex-column align-items-center justify-content-center">
                        <label htmlFor="dd-task" className="form-label">
                          &nbsp;
                        </label>
                        <Button
                          label={"Remove"}
                          className="p-button-danger p-button-rounded"
                          size="small"
                          onClick={() => {
                            removeRow(key);
                          }}
                        ></Button>
                      </div>
                    </div>
                  );
                })}
                <div className="row">
                  <div className="col-12 text-right">
                    <Button
                      label={"Add New Step"}
                      onClick={addNewRow}
                      className="p-button-secondary p-button-rounded mr-2"
                    ></Button>
                    {formData.steps.length > 0 && (
                      <Button
                        label={"Submit"}
                        disabled={isBusy}
                        className="p-button-success p-button-rounded"
                        size="small"
                        onClick={saveData}
                      ></Button>
                    )}
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Task;
