import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import Tags from "../tags/tags";
import Groups from "../groups/groups";
import OtherContact from "../other-contact/other-contact";
import NonRoutineTask from "../non-routine-task/non-routine-task";

const Settings = () => {
  const [mode, setMode] = useState(1);
  const location = useLocation();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const modeParam = queryParams.get("mode");
    if (modeParam) {
      setMode(parseInt(modeParam));
    }
  }, [location.search]);

  const handleModeChange = (newMode) => {
    setMode(newMode);
    const queryParams = new URLSearchParams(location.search);
    queryParams.set("mode", newMode);
    window.history.pushState({}, "", `${location.pathname}?${queryParams.toString()}`);
  };

  return (
    <>
      <div className="w-100">
        <div className="card shadow-sm ctm-border-radius">
          <div className="card-body">
            <div
              className="flex-row list-group list-group-horizontal-lg"
              id="v-pills-tab"
              role="tablist"
              aria-orientation="vertical"
            >
              <a
                className={mode === 1 ? "active list-group-item" : "list-group-item"}
                id="v-pills-home-tab"
                data-toggle="pill"
                href="#v-pills-home"
                role="tab"
                aria-controls="v-pills-home"
                aria-selected={mode === 1}
                onClick={() => handleModeChange(1)}
              >
                Tags
              </a>
              <a
                className={mode === 2 ? "active list-group-item" : "list-group-item"}
                id="v-pills-profile-tab"
                data-toggle="pill"
                href="#v-pills-profile"
                role="tab"
                aria-controls="v-pills-profile"
                aria-selected={mode === 2}
                onClick={() => handleModeChange(2)}
              >
                Groups
              </a>
              <a
                className={mode === 3 ? "active list-group-item" : "list-group-item"}
                id="v-pills-profile-tab"
                data-toggle="pill"
                href="#v-pills-profile"
                role="tab"
                aria-controls="v-pills-profile"
                aria-selected={mode === 3}
                onClick={() => handleModeChange(3)}
              >
                Other Contact
              </a>
              <a
                className={mode === 4 ? "active list-group-item" : "list-group-item"}
                id="v-pills-profile-tab"
                data-toggle="pill"
                href="#v-pills-profile"
                role="tab"
                aria-controls="v-pills-profile"
                aria-selected={mode === 4}
                onClick={() => handleModeChange(4)}
              >
                Non Routine
              </a>
            </div>
          </div>
        </div>
        {mode === 1 && <Tags />}
        {mode === 2 && <Groups />}
        {mode === 3 && <OtherContact />}
        {mode === 4 && <NonRoutineTask />}
      </div>
    </>
  );
};

export default Settings;